import React from "react";
import NavContact from "../navbar/[navcontact]";
import ExampageNavbar from "../navbar/[exampagenavbar]";
import Secfooter from "../../components/footers/Secfooter";
import { Link } from "react-router-dom";

const WaecSubmit = () => {
  return (
    <div className=" mx-auto overflow-x-hidden">
      <NavContact />
      <ExampageNavbar />
      <div className=" grid px-8 md:px-12 lg:px-20 gap-10">
        <div className=" grid gap-10">
          <div className="">
            <p className=" font-Segoe text-exampagecol font-bold text-[28.49px]">
              Well done Debbie!
            </p>
          </div>
          <ul className=" lg:flex md:grid-cols-2 grid items-center gap-3">
            <li className=" flex items-center gap-2 px-2 py-2 shadow-md">
              <p className=" text-[16.03px] text-exampagecol font-bold">
                Exam Name :{" "}
              </p>
              <img
                className="w-[62.33px] h-[21.37px]"
                src={require("../../assets/WAEC.png")}
                alt=""
              />
            </li>

            {/* SUBJECT */}
            <li className=" flex items-center gap-2 px-2 py-2 shadow-md">
              <p className=" text-[16.03px] text-exampagecol font-bold">
                Subject : Biology
              </p>
            </li>
            {/* COMPREHENSION */}
            <li className=" flex items-center gap-2 px-2 py-2 shadow-md">
              <p className=" text-[16.03px] text-exampagecol font-bold">
                Year: Comprehension
              </p>
            </li>
            {/* IN PROGRESS */}
            <li className=" flex items-center gap-2 px-2 py-2 shadow-md">
              <p className=" text-[16.03px] text-exampagecol font-bold">
                In Progress: Question 22
              </p>
            </li>
          </ul>
          <div className=" lg:flex md:grid grid  gap-5 lg:justify-between  items-center">
            <div className=" lg:w-[480.67px] md:w-full w-full h-[73.01px] shadow-md px-5 py-2">
              <div className=" flex items-center justify-between">
                <p className=" text-[14.25px] font-Segoe text-exampagecol font-bold">
                  Test Progress
                </p>
                <p className=" text-[12.47px] font-bold">50</p>
              </div>
              <div className=" w-full h-[17.81px]">
                <div className=" lg:w-[326.76px] md:w-full w-full rounded-md bg-testprogcol flex justify-center items-center">
                  <p> In Progress: Question 22</p>
                </div>
              </div>
            </div>

            <div className=" lg:w-[580.87px] md:w-full w-full h-[73.01px] shadow-md px-5 py-2">
              <div className=" flex items-center justify-between">
                <p className=" text-[14.25px] font-Segoe text-exampagecol font-bold">
                  Total Time
                </p>
                <p className=" text-[12.47px] font-bold">00:60:00</p>
              </div>
              <div className=" w-full h-[17.81px]">
                <div className=" lg:w-[146.02px] rounded-md bg-testprogcol flex justify-center items-center">
                  <p>00:25:00</p>
                </div>
              </div>
            </div>
          </div>

          <div className=" lg:flex md:flex grid lg:justify-between md:justify-center justify-center lg:gap-0 md:gap-0 gap-5 items-center ">
            <div className=" lg:w-[782px] lg:h-[404px] md:w-[382px] md:h-[404px] w-[300px] h-[304px] card shadow-lg grid justify-center items-center">
              <div className=" lg:w-[755.84px] lg:h-[354.36px]  md:w-[355.84px] md:h-[254.36px] w-[255.84px] h-[250.36px]   bg-correctanscol overflow-y-scroll shadow-md grid justify-center items-center">
                <p className=" text-[21.37px] text-exampagecol">
                  Go back to Question 22
                </p>
              </div>
            </div>
            <div className=" w-[285px] h-[404px] grid items-center card shadow-md">
              <div className=" grid justify-center gap-5 items-center">
                <ul className=" grid lg:grid-cols-5 md:grid-cols-5 grid-cols-5 gap-5">
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    1
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    2
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    3
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    4
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center border border-exampagecol bg-white text-exampagecol">
                    5
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    6
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center border border-exampagecol bg-white text-exampagecol">
                    7
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    8
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    9
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    10
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    11
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center border border-exampagecol bg-white text-exampagecol">
                    12
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    13
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    14
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    15
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center border border-exampagecol bg-white text-exampagecol">
                    16
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center border border-exampagecol bg-white text-exampagecol">
                    17
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    18
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    19
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    20
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    21
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    22
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    23
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    24
                  </li>
                  <li className=" w-[24.04px] h-[24.04px] grid cursor-pointer justify-center items-center bg-exampagecol font-Segoe text-white">
                    25
                  </li>
                </ul>
                <div>
                  <img
                    className=" w-[226.15px]"
                    src={require("../../assets/Submitline.png")}
                    alt=""
                  />
                </div>
                <div className=" grid grid-cols-2 gap-5">
                  <div className=" flex gap-2 ">
                    <div className=" w-[24.04px] h-[24.04px] bg-exampagecol text-white grid justify-center items-center">
                      1
                    </div>
                    <p>Attempted</p>
                  </div>
                  <div className=" flex gap-2">
                    <div className=" w-[24.04px] h-[24.04px] bg-white text-exampagecol border border-exampagecol grid justify-center items-center">
                      1
                    </div>
                    <p>Attempted</p>
                  </div>
                  <div className=" flex gap-2">
                    <div className=" w-[24.04px] h-[24.04px] bg-jambpagetesultcol text-white grid justify-center items-center">
                      1
                    </div>
                    <p>Attempted</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" flex justify-center items-center py-20 gap-3">
            <Link
              to="/waec-mathematics"
              className=" lg:w-[200px] md:w-[160px] w-[150px] lg:h-[36px] md:h-[28px] h-[28px] flex justify-center items-center  rounded-full text-white bg-exampagetestcol font-Segoe"
            >
              Continue to practice
            </Link>
            <Link
              to="/jamb-result"
              className=" lg:w-[200px] md:w-[160px] w-[150px] lg:h-[36px] md:h-[28px] h-[28px] flex justify-center items-center py-2 rounded-full text-white bg-navbtncol font-Segoe"
            >
              Submit anyways
            </Link>
          </div>
        </div>
      </div>

      {/* footer */}
      <Secfooter />
    </div>
  );
};

export default WaecSubmit;
