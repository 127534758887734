import React, { useEffect } from "react";
import NavContact from "../navbar/[navcontact]";
import SecNav from "../navbar/[secnav]";
import Testimonialimg from "../../assets/Testimonialimg.png";
import Testimonialimg2 from "../../assets/Testimonialimg2.png";
import Firstfooter from "../../components/footers/Firstfooter";
import ExamType from "../../components/exam-type/ExamType";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// testimonial

const Testicard = ({ imageSrc, text, username, user }) => {
	return (
		<div className="w-[290px] md:w-full lg:w-[300px] p-5 gap-8 grid h-auto rounded overflow-hidden shadow-lg m-4 transition-transform hover:scale-110 duration-300 ease-in">
			<div className=" w-full">
				<div className="text-[14px] font-Poppins">{text}</div>
			</div>
			<div className="flex justify-center items-center gap-3">
				<div>
					<img src={imageSrc} alt={text} className=" w-[48px] h-[48px]" />
				</div>
				<div className="grid justify-center items-center">
					<p>{username}</p>
					<p>{user}</p>
				</div>
			</div>
		</div>
	);
};

const Exampage = () => {
	useEffect(() => {
		AOS.init();
	}, []);
	// testimolial

	const testimonialCardData = [
		{
			imageSrc: Testimonialimg,
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel",
			username: "GET STARTED",
			user: "User",
		},
		{
			imageSrc: Testimonialimg2,
			text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis leo, vel",
			username: "GET STARTED",
			user: "User",
		},
	];

	let { auth } = useSelector(s => s),
		navigate = useNavigate();

	useEffect(() => {
		if (!auth?.isAuth) return navigate("/");
	}, [auth?.isAuth, navigate]);

	return (
		<div className="mx-auto overflow-x-hidden">
			<NavContact />

			<div className=" bg-exampagecol mx-auto">
				<SecNav />
				<div className="lg:flex md:grid grid justify-center lg:gap-5 md:gap-16 gap-16 items-center text-white px-8 md:px-12 lg:px-20 pt-12">
					<div className=" lg:w-1/2 md:w-full w-full grid justify-center items-center space-y-14">
						<div className=" space-y-5">
							<p className=" lg:text-[48px] md:text-[38px] text-[38px] font-Segoe">
								You are welcome to{" "}
								<span className="lg:text-[48px] md:text-[38px] text-[38px] font-Segoe font-bold">
									Screenclass Exams Prep
								</span>
							</p>
							<p className=" text-[24px] font-Poppins">
								Lorem ipsum dolor sit amet consectetur. Vitae duis nibh sed
								commodo. Fames odio sed quis venenatis. Senectus bibendum.
							</p>
						</div>
						<button className=" w-[188px] h-[50px] bg-navbtncol font-Segoe rounded-md cursor-pointer transition-transform hover:scale-110 duration-300 ease-in">
							GET STARTED
						</button>
					</div>
					<div className="lg:w-1/2 md:w-full w-full grid justify-center items-center">
						<img src={require("../../assets/Exampagrhero.png")} alt="" />
					</div>
				</div>
			</div>

			{/* section1 Top categories */}
			<div className=" grid justify-center items-center px-8 md:px-12 lg:px-20 gap-10  py-10 mx-auto">
				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className=" lg:flex md:grid grid justify-center items-center gap-5">
					<div className=" lg:w-2/5 md:w-full w-full flex items-center justify-between">
						<div className=" grid items-center space-y-5">
							<div>
								<p className=" text-[20px] text-navbtncol font-Poppins">
									TOP CATEGORIES
								</p>
							</div>
							<div>
								<p className=" text-[32px] font-alata">
									Learn And Practice Anywhere At Your Convenience{" "}
								</p>
							</div>
						</div>
						<div className=" grid">
							<img
								className=" h-[170px] w-auto lg:block md:hidden hidden"
								src={require("../../assets/line.png")}
								alt=""
							/>
						</div>
					</div>
					<div className=" lg:w-3/5 md:w-full w-full">
						<p className=" text-[20px] font-Poppins">
							Lorem ipsum dolor sit amet consectetur. Id in tempor dui non nunc
							leo. Imperdiet mi arcu ornare vehicula elit ullamcorper duis. Odio
							egestas ut est aliquet nullam tempus. Pretium donec euismod
							rhoncus est fusce. Mattis nibh scelerisque vel nisl nulla eget
							nunc orci. Tellus elit ut dui.
						</p>
					</div>
				</div>

				{/* cards */}

				<div data-aos="fade-left" data-aos-duration="3000">
					<ExamType />
				</div>
				<div className=" flex justify-end ">
					<div className=" flex items-center gap-3">
						<img
							className=" w-[40px] h-[40px] cursor-pointer"
							src={require("../../assets/Arrowleft.png")}
							alt=""
						/>
						<img
							className=" w-[40px] h-[40px] cursor-pointer"
							src={require("../../assets/Arrowright.png")}
							alt=""
						/>
					</div>
				</div>
			</div>

			{/* section2 Optimize your lerning */}

			<div className=" bg-exampageseccol mx-auto">
				<div
					data-aos="fade-up"
					data-aos-duration="3000"
					className=" grid justify-center py-10 space-y-10 items-center px-8 md:px-12 lg:px-20">
					<div className=" grid justify-center space-y-5">
						<div className="grid justify-center">
							<p className="lg:text-[40px] md:text-[30px] text-[20px] text-center font-alata">
								Optimize Your Learning Today, Easy And Affordable!
							</p>
						</div>

						<div className=" grid justify-center">
							<p className=" text-[24px] lg:w-[700px] font-nunito text-center">
								Lorem ipsum dolor sit amet consectetur. Mauris erat tortor dolor
								nunc interdum. Lacinia nunc.
							</p>
						</div>
					</div>
					<div className=" flex gap-10 justify-center items-center">
						<div className="grid justify-center items-center">
							<p className=" text-gradient font-alata lg:text-[48px] md:text-[38px] text-[20px]">
								4o+
							</p>
							<p className=" md:text-[16px] text-[10px] lg:text-center md:text-center">
								Years Treated
							</p>
						</div>
						<div className="grid justify-center items-center">
							<p className=" text-gradient font-alata lg:text-[48px] md:text-[38px] text-[20px]">
								200k+
							</p>
							<p className=" md:text-[16px] text-[10px] lg:text-center md:text-center">
								Questions
							</p>
						</div>
						<div className="grid justify-center items-center">
							<p className=" text-gradient font-alata lg:text-[48px] md:text-[38px] text-[20px]">
								100k+
							</p>
							<p className=" md:text-[16px] text-[10px] lg:text-center md:text-center">
								Subjects Covered
							</p>
						</div>
					</div>
				</div>
			</div>
			<div
				data-aos="fade-down"
				data-aos-duration="3000"
				className=" grid justify-center gap-5 items-center py-10 px-8 md:px-12 lg:px-20">
				<div className=" grid ">
					<p className=" font-alata text-gradient lg:text-[48px] md:text-[38px] text-[38px] text-center">
						TESTIMONIES
					</p>
					<p className=" font-Poppins text-[24px] text-center">
						Hear What Our Users Have To Say
					</p>
				</div>
				<div className=" lg:flex md:grid grid justify-center items-center">
					<div className=" lg:w-2/5 md:w-full w-full grid justify-center items-center">
						<p className=" font-alata text-[32px] lg:w-[270px] md:w-full w-full lg:text-start md:text-center text-center">
							Trusted by millions of users.
						</p>
					</div>
					<div className=" lg:w-3/5 md:w-full w-full">
						<div className=" grid lg:grid-cols-2 md:grid-cols-2  gap-20 justify-center items-center">
							{testimonialCardData.map((cards, index) => (
								<Testicard key={index} {...cards} />
							))}
						</div>
					</div>
				</div>
			</div>

			{/* footer */}

			<Firstfooter />
		</div>
	);
};

export default Exampage;
