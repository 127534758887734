import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import NavContact from "../navbar/[navcontact]";
import ExampageNavbar from "../navbar/[exampagenavbar]";
import Secfooter from "../../components/footers/Secfooter";
import AnswerButton from "../../components/answer-buttons/AnswerButton";
import QuestionAndAnswer from "../../components/question-and-answer/QuestionAndAnswer";
import { useLocation, useNavigate } from "react-router-dom";
import SubmintAlert from "../../components/submit-alert";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import { Buttons, LoadingLoader } from "../../components/button/button";
import moment from "moment";
import Countdown from "react-countdown";

const ExamQuestionsAnswer = () => {
	let [currentPage, setCurrentPage] = useState(1);

	const handleAnswerButtonClick = answerNo => {
		// Set the current page based on the answer number
		setCurrentPage(Number(answerNo));
		let d = questions?.[answerNo - 1];
		let findQue = [
			...answerArr?.map(item =>
				item?.question === d?._id
					? {
							...item,
							isVisited: true,
					  }
					: item
			),
		];
		setAnswerArr(findQue);
	};

	const [showAlert, setShowAlert] = useState(false);

	const handleShowAlert = () => {
		setShowAlert(true);
	};

	const handleCloseAlert = () => {
		setShowAlert(false);
	};

	let { state } = useLocation(),
		navigate = useNavigate(),
		{ subject, year, examType } = useSelector(s => s),
		dispatch = useDispatch(),
		[loading, setLoading] = useState(false),
		[questions, setQuestions] = useState(null),
		[answerArr, setAnswerArr] = useState(null),
		[isSelected, setIsSelected] = useState(null),
		[exam, setExam] = useState(null),
		[duration, setDuration] = useState(0),
		[isTime, setIsTime] = useState(null),
		[initTime, setInitTime] = useState(null),
		handleSubmit = async () => {
			setLoading(true);
			try {
				let res = await axios.get(
					`/api/v1/exam?examType=${state?._id}&subject=${state?.subject}&year=${state?.year}`,
					{
						data: {
							...state,
							year: state?.year,
							subject: state?.subject,
						},
					}
				);
				// console.log({ resp: res?.data });
				setQuestions(res?.data?.data?.questions);
				setExam(res?.data?.data?._id);
				setDuration(res?.data?.data?.duration);
				setAnswerArr([
					...res?.data?.data?.questions?.map(item => {
						return { question: item?._id };
					}),
				]);
				if (!initTime) {
					toast.success(res?.data?.message);
					setInitTime(moment());
				}
			} catch (err) {
				if (err?.response?.status === 429 || err?.response?.status === 405)
					toast.error(err?.response?.data ? err?.response?.data : err?.message);
				console.log({ err });
				let error = err.response?.data?.error;
				if (error && error?.length > 1) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				} else {
					toast.error(err?.response?.data?.message);
				}
				setQuestions(null);
			}
			setLoading(false);
		},
		mainHandleSubmit = async e => {
			try {
				let res;
				// console.log({ query });
				let d = questions?.[currentPage - 1];
				let findQue = [
					...answerArr?.map(item =>
						item?.question === d?._id
							? {
									...item,
									option: isSelected?.answer,
							  }
							: item
					),
				];
				setLoading(true);
				res = await axios.post(`/api/v1/exam/answer`, {
					answers: findQue,
					exam,
					timeSpent: moment().diff(initTime, "minutes"),
				});

				toast.success(res?.data?.message);
				setLoading(false);
				setIsSelected(null);
				// setResult(res?.data?.data?.questions);
				// setIsQuestion(isQuestion + 1);
				navigate("/exam-selection/submission", {
					state: { ...res?.data?.data, questions },
				});
				// console.log({ result: res?.data });
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				let error = err.response?.data?.error;
				if (error) {
					dispatch(returnErrors({ error, status: err?.response?.status }));
				} else {
					toast.error(err?.response?.data?.message);
				}
				setLoading(false);
			}
		};

	useEffect(() => {
		if (state) handleSubmit();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	// console.log({ state, questions, initTime, now: moment(), duration });

	const handleNext = useCallback(() => {
		if (isSelected) {
			let d = questions?.[currentPage - 1];
			let findQue = [
				...answerArr?.map(item =>
					item?.question === d?._id
						? {
								...item,
								option: isSelected?.answer,
								isVisited: true,
						  }
						: item
				),
			];
			setAnswerArr(findQue);
			if (currentPage !== questions?.length) {
				setIsSelected(null);
				setCurrentPage(currentPage + 1);
			} else handleShowAlert();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSelected, currentPage, questions, answerArr]);
	const handlePrev = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
			setIsSelected(null);
		}
	};
	useEffect(() => {
		if (isSelected && questions?.length !== currentPage) {
			const timer = setTimeout(() => handleNext(), 1000);
			return () => clearTimeout(timer);
		}
	}, [isSelected, handleNext, currentPage, questions]);
	const Completionist = () => <small>Time up!</small>;
	const Renderer = ({ hours, minutes, seconds, completed }) => {
		useEffect(() => {
			if (completed && !isTime) {
				setIsTime(true);
				mainHandleSubmit();
			}
		}, [completed]);

		if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {
			// Render a countdown
			return (
				<small>
					{hours}h:{minutes}m:{seconds}s
				</small>
			);
		}
	};

	if (loading && !questions) return <LoadingLoader />;
	// [data, setData] = useState({});

	if (!state) return navigate(-1);

	return (
		<div className=" mx-auto overflow-x-hidden">
			<NavContact />
			<ExampageNavbar />
			<div className=" grid px-8 md:px-12 lg:px-20 gap-10">
				<div>
					<p className=" text-[24px] font-Segoe uppercase">
						{examType?.data?.docs?.find(it => it?._id === state?._id)?.title}{" "}
						{year?.data?.docs?.find(it => it?._id === state?.year)?.title}{" "}
						{subject?.data?.docs?.find(it => it?._id === state?.subject)?.title}{" "}
						PAST QUESTIONS
					</p>
					{duration && (
						<div className="flex justify-end items-center text-4xl">
							Time left:{" "}
							<Countdown
								date={moment(initTime).add(duration, "minutes")}
								renderer={da => <Renderer {...da} />}
							/>
						</div>
					)}
				</div>
				{/* Question card */}
				<QuestionAndAnswer
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
					questions={questions}
					setIsSelected={setIsSelected}
					isSelected={isSelected}
					answerArr={answerArr}
				/>

				<div className=" flex justify-between items-center lg:px-28">
					{currentPage > 1 && (
						<button
							type="button"
							onClick={handlePrev}
							className=" flex items-center cursor-pointer w-[194px] h-[50px] justify-start bg-exampageseccol rounded-full">
							<img
								className=" w-[50px] h-[50px]"
								src={require("../../assets/Prev.png")}
								alt=""
							/>
							<p>Previous</p>
						</button>
					)}
					<div className="ms-auto">
						{currentPage !== questions?.length ? (
							<button
								type="button"
								onClick={handleNext}
								className=" flex items-center cursor-pointer w-[194px] h-[50px] justify-end bg-exampageseccol rounded-full">
								<p>Next</p>
								<img
									className=" w-[50px] h-[50px]"
									src={require("../../assets/Nextimg.png")}
									alt=""
								/>
							</button>
						) : (
							// <Link
							// 	to="#"
							// 	className="cursor-pointer w-[158px] h-[46px] flex justify-center items-center text-white bg-navbtncol rounded-full">
							// 	Submit
							// </Link>
							<Buttons
								onClick={handleNext}
								loading={loading}
								title={"Submit"}
								width={
									"cursor-pointer w-[158px] h-[46px] flex justify-center items-center text-white bg-navbtncol rounded-full"
								}
							/>
						)}
					</div>
				</div>
				<div className=" lg:px-20">
					<div>
						<p className=" font-Segoe font-bold">Jump to Question:</p>
					</div>
					{/* answer card */}
					<div className="md:py-10">
						<AnswerButton
							onAnswerButtonClick={handleAnswerButtonClick}
							questions={questions}
						/>
					</div>
					{/* <div className=" flex justify-center py-10">
						<Link
							onClick={handleShowAlert}
							className=" w-[285px] h-[51px] bg-exampageseccol font-Segoe flex justify-center items-center">
							SUBMIT
						</Link>
					</div> */}
				</div>
			</div>

			{/* footer */}
			{showAlert && (
				<div className="fixed inset-0 flex items-center justify-center z-50">
					<SubmintAlert
						// the card message
						message="Are you sure you want to submit now? Action cannot be undone."
						onClose={handleCloseAlert}
						onConfirm={() => {
							handleCloseAlert();
							mainHandleSubmit();
						}}
						alertClass="bg-white p-6 rounded shadow-md"
					/>
				</div>
			)}
			<Secfooter />
		</div>
	);
};

export default ExamQuestionsAnswer;
