import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUser, login, setUserFail } from "../data/Reducers/UserReducer";
import { useNavigate } from "react-router-dom";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { toast } from "react-toastify";
import axios from "axios";
import { returnErrors } from "../data/Reducers/ErrorReducer";
import { Buttons } from "../components/button/button";

const Home = () => {
	const [state, setState] = useState({}),
		textChange = e => {
			let { name, value } = e?.target;
			setState(prev => {
				return { ...prev, [name]: value };
			});
		};

	// const handleLogin = () => {
	// 	// Add your login logic here
	// 	console.log("Email:", email);
	// 	console.log("Password:", password);
	// 	dispatch(login({ token: "EXAMPREPADMIN_LOGIN" }));
	// 	dispatch(loadUser());
	// 	navigate("/");
	// };

	const [show, setShow] = useState(false),
		navigate = useNavigate(),
		[loading, setLoading] = useState(false),
		[submit, setSubmit] = useState(false),
		{ auth } = useSelector(state => state),
		dispatch = useDispatch();

	useEffect(() => {
		if (auth?.isLoggedIn && submit) {
			navigate("/");
		}
	}, [auth, submit, navigate]);

	const handleSubmit = async e => {
		e?.preventDefault();
		if (!state?.telephone || !state?.password) return;
		setLoading(true);
		try {
			let res = await axios.post(`/api/v1/user/login`, state);
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			dispatch(login(res?.data));
			dispatch(loadUser());
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
			dispatch(setUserFail());
		}
		setLoading(false);
		setSubmit(true);
	};

	return (
		<div className="w-full flex items-center justify-between">
			<div className="w-1/2 flex flex-col my-20 p-10">
				{/* Logo */}
				<div className="w-96">
					<img
						src={require("../assets/screen-dark 2.png")}
						alt="screen logo"
						className="mb-14"
					/>
					<div className="mb-3">
						<h3 className="my-3 text-3xl text-[#2C56AF]">Login</h3>
						<p className="mb-14 text-lg text-[#2C56AF]">
							Login to exams prep solutions.
						</p>
					</div>
				</div>

				{/* Email Input  */}
				<div className="mb-6">
					<label
						className="block text-gray-700 text-lg font-semibold mb-2"
						htmlFor="email">
						Your Phone number
					</label>
					<input
						type="tel"
						id="telephone"
						className="w-full py-2 px-3 border rounded shadow appearance-none text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
						placeholder="080********"
						value={state?.telephone}
						onChange={textChange}
						name="telephone"
					/>
				</div>
				<div className="mb-6">
					<label
						className="block text-gray-700 text-lg font-bold mb-2"
						htmlFor="password">
						Password
					</label>
					<div className="border flex gap-3 items-center p-2 rounded shadow">
						<input
							type={show ? "text" : "password"}
							id="password"
							className="w-full py-2 px-3 rounded border-0 appearance-none text-gray-700 leading-tight focus:outline-none focus:border-0"
							placeholder="Password"
							value={state?.password}
							onChange={textChange}
							name="password"
						/>
						<span onClick={() => setShow(!show)}>
							{show ? (
								<AiFillEyeInvisible className="icon text-gray-400" size={24} />
							) : (
								<AiFillEye className="icon text-gray-400" size={24} />
							)}
						</span>
					</div>
				</div>
				<div className="mb-6">
					<input
						type="checkbox"
						id="checkbox"
						className="rounded shadow appearance-none text-[#2C56AF] leading-tight focus:outline-none focus:shadow-outline"
					/>
					<label
						className="text-gray-400 text-sm space-x-2 font-bold mb-2 ml-3"
						htmlFor="checkbox">
						Keep me logged in
					</label>
				</div>
				<div className="flex items-center justify-around ">
					{/* <button
						className="bg-[#30388F] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
						onClick={handleLogin}>
						Login
					</button> */}
					<Buttons
						onClick={handleSubmit}
						loading={loading}
						title={"Login"}
						width={
							"bg-[#30388F] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
						}
					/>
				</div>
			</div>

			{/* Image */}
			<div className="w-1/2">
				<img
					src={require("../assets/login_screen_3.png")}
					alt="Login"
					className="w-[700px] h-[1000px] absolute top-0 right-0"
				/>
			</div>
		</div>
	);
};

export default Home;
