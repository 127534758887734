import React from "react";
import { useState } from "react";
import NavContact from "../navbar/[navcontact]";
import ExampageNavbar from "../navbar/[exampagenavbar]";
import Secfooter from "../../components/footers/Secfooter";

// ANSWER CARD
const Card = ({
  question_no,
  question,
  answer_a,
  answer_b,
  answer_c,
  answer_d,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const handleAnswerClick = (answer) => {
    setSelectedAnswer(answer);
  };

  return (
    <div className="grid gap-5">
      <div>
        <p>{question_no}</p>
      </div>
      <div className="card p-5 shadow-md grid items-center gap-5">
        <div>{question}</div>
        {/* answers */}
        <div className="grid gap-3">
          <div className="flex items-center px-5">
            {/* answer A */}
            <div
              className={`bg-exampageseccol px-2 w-full rounded-lg border h-[48px] flex items-center ${
                selectedAnswer === "A" ? "border-purple-500" : ""
              }`}
              onClick={() => handleAnswerClick("A")}
            >
              A. {answer_a}
            </div>
          </div>
          <div className="flex items-center px-5">
            {/* Answer B */}
            <div
              className={`bg-exampageseccol px-2 rounded-lg border w-full h-[48px] flex items-center ${
                selectedAnswer === "B" ? "border-purple-500" : ""
              }`}
              onClick={() => handleAnswerClick("B")}
            >
              B. {answer_b}
            </div>
          </div>
          <div className="flex items-center px-5">
            {/* Answer C */}
            <div
              className={`bg-exampageseccol border rounded-lg px-2 w-full h-[48px] flex items-center ${
                selectedAnswer === "C" ? "border-purple-500" : ""
              }`}
              onClick={() => handleAnswerClick("C")}
            >
              C. {answer_c}
            </div>
          </div>
          <div className="flex items-center px-5">
            {/* Answer D */}
            <div
              className={`bg-exampageseccol border rounded-lg px-2 w-full h-[48px] flex items-center ${
                selectedAnswer === "D" ? "border-purple-500" : ""
              }`}
              onClick={() => handleAnswerClick("D")}
            >
              D. {answer_d}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// THE ANSWER BUTTONS

const cardsData = [
  {
    question_no: "Question 7",
    question: "What is the capital of France?",
    answer_a: "Paris",
    answer_b: "Berlin",
    answer_c: "Madrid",
    answer_d: "Rome",
  },
];

const BioFourthPage = () => {
  return (
    <div className=" mx-auto">
      <NavContact />
      <ExampageNavbar />
      <div className=" grid px-8 md:px-12 lg:px-20 py-10 gap-10">
        <div>
          <p className=" text-[24px] font-Segoe">2012 BIOLOGY PAST QUESTIONS</p>
        </div>
        <div className="grid items-center lg:px-28 gap-10">
          {cardsData.map((card, index) => (
            <Card key={index} {...card} />
          ))}
        </div>

        <div className=" flex justify-between items-center lg:px-28">
          <button className=" flex items-center cursor-pointer lg:w-[194px] md:w-[164px] w-[120px] h-[50px] justify-start bg-exampageseccol rounded-full">
            <img
              className=" w-[50px] h-[50px]"
              src={require("../../assets/Prev.png")}
              alt=""
            />
            <p>Previous</p>
          </button>
          <button className="cursor-pointer w-[158px] h-[46px] text-white bg-navbtncol rounded-full">
            Submit
          </button>
        </div>
      </div>

      {/* footer */}

      <Secfooter />
    </div>
  );
};

export default BioFourthPage;
