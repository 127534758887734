import React, { useEffect } from "react";
import NavContact from "./navbar/[navcontact]";
import Navbar from "./navbar/[navbar]";
import ExamType from "../components/exam-type/ExamType";
import Heroimg from "../assets/Heroimg.png";
import Testimonialbg from "../assets/Testimonialbg.jpg";
import Firstfooter from "../components/footers/Firstfooter";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";

const LandingPage = () => {
	let { auth } = useSelector(s => s);
	useEffect(() => {
		AOS.init();
	}, []);

	return (
		<div className="mx-auto overflow-x-hidden ">
			<NavContact />
			<div
				className="bg-cover bg-no-repeat bg-center h-full w-full"
				style={{ backgroundImage: `url(${Heroimg})` }}>
				<Navbar />

				{/* Hero */}
				<div className=" grid px-8 md:px-12 lg:px-20 justify-center items-center space-y-20 lg:py-[70px] md:py-[70px] py-[65px] mx-auto">
					<div className=" grid justify-center items-center space-y-11">
						<div className="  text-white grid justify-center items-center">
							<div className="w-full lg:max-w-[700px]">
								<p className=" lg:text-[48px] md:text-[38px] text-[38px] text-center font-segoe">
									Explore The World Of Learning{" "}
									<span className="lg:text-[48px] md:text-[38px] text-[38px]  text-center font-bold font-segoe">
										Screenclass Exams Solutions
									</span>
								</p>
							</div>
						</div>
						<div className="  text-white grid justify-center items-center">
							<div className=" lg:max-w-[770px]">
								<p className=" font-Poppins text-center">
									Lorem ipsum dolor sit amet consectetur. Vitae duis nibh sed
									commodo. Fames odio sed quis venenatis. Senectus bibendum.
								</p>
							</div>
						</div>
					</div>
					{/* button */}
					<div className=" flex justify-center items-center space-x-10">
						<Link
							to={auth?.isAuth ? "/exam-page" : "/login"}
							className=" lg:w-[188px] md:w-[160px] w-[120px] lg:h-[50px] md:h-[46px] h-[46px] font-segoe lg:text-[18px] rounded-full text-white bg-navbtncol grid items-center justify-center">
							GET STARTED
						</Link>
						<button className=" flex items-center justify-center font-segoe text-white space-x-3">
							<p>READ MORE</p>
							<img
								className=" w-[16px] h-[12px]"
								src={require("../assets/Landingarrow.png")}
								alt=""
							/>
						</button>
					</div>
				</div>
			</div>

			{/* section1 */}
			<div className=" grid px-8 md:px-12 lg:px-20 space-y-10 py-10 mx-auto">
				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className=" grid justify-center items-center">
					<p className=" text-[16px] text-navbtncol font-Public+Sans font-bold">
						WELCOME TO SCREENCLASS CBT SOLUTIONS
					</p>
				</div>
				<div className="grid justify-center items-center ">
					<div data-aos="fade-right" data-aos-duration="3000" className="">
						<p className=" text-[24px] text-center lg:w-[637px] ">
							We Are Ready To Give You A New Experience Through Exams Prep{" "}
							<span className="text-[24px] font-bold">Solutions</span>
						</p>
					</div>
				</div>

				<div
					data-aos="fade-left"
					data-aos-duration="3000"
					className=" lg:flex md:grid grid justify-center items-center gap-3">
					<div className=" lg:w-1/2 md:w-full w-full grid justify-center items-center lg:p-3">
						<div>
							<img src={require("../assets/Firstimg.png")} alt="" />
						</div>
					</div>
					<div className=" lg:w-1/2 md:w-full w-full grid justify-center items-center gap-3">
						<div>
							<img src={require("../assets/Frametwo.png")} alt="" />
						</div>
						<div className=" flex justify-center items-center gap-3">
							<div>
								<img src={require("../assets/Frame3.png")} alt="" />
							</div>
							<div>
								<img src={require("../assets/Frame4.png")} alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* section2 */}
			<div className="lg:flex gap-10 justify-center items-center md:grid grid px-8 md:px-12 lg:px-20 mx-auto">
				<div
					data-aos="fade-down"
					data-aos-duration="3000"
					className=" lg:w-1/2 md:w-full w-full grid space-y-20   justify-center items-center">
					<div className=" grid justify-center items-center space-y-5">
						<div>
							<p className=" font-Alata font-semibold lg:text-[32px] md:text-[26] text-[24px]">
								100, 000+ PRACTICE QUESTIONS
							</p>
						</div>
						<div>
							<p>
								Lorem ipsum dolor sit amet consectetur. Quisque id semper
								elementum donec. Id in faucibus ornare suspendisse porttitor
								diam. Quis pulvinar enim suscipit ut. Tincidunt risus egestas
								rhoncus habitasse. Rhoncus justo condimentum orci feugiat.
								Libero aliquet lectus egestas nisl nam varius aliquet id
							</p>
						</div>
					</div>

					{/* button */}
					<div>
						<button className=" font-Segoe text-navbtncol border-4 border-navbtncol rounded-md w-[188px] h-[50px] cursor-pointer transition-transform hover:scale-110 duration-300 ease-in">
							READ MORE
						</button>
					</div>
				</div>
				<div className=" lg:w-1/2 md:w-full grid justify-center items-center w-full">
					<div data-aos="fade-up" data-aos-duration="1000" className=" lg:p-3 ">
						<img src={require("../assets/Frame6.png")} alt="" />
					</div>
				</div>
			</div>

			{/* section3 */}

			<div className="lg:flex md:grid grid justify-center items-center gap-10 px-8 md:px-12 lg:px-20 py-10 mx-auto">
				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className=" lg:w-1/2 md:w-full w-full grid justify-center items-center">
					<div className=" p-3">
						<img src={require("../assets/Frame7.png")} alt="" />
					</div>
				</div>
				<div
					data-aos="fade-left"
					data-aos-duration="3000"
					className=" lg:w-1/2 md:w-full w-full space-y-20">
					<div className="grid justify-center items-center space-y-5">
						<div>
							<p className=" font-Alata text-[32px]">30 SUBJECTS COVERED</p>
						</div>
						<div>
							<p>
								Lorem ipsum dolor sit amet consectetur. Quisque id semper
								elementum donec. Id in faucibus ornare suspendisse porttitor
								diam. Quis pulvinar enim suscipit ut. Tincidunt risus egestas
								rhoncus habitasse. Rhoncus justo condimentum orci feugiat.
								Libero aliquet lectus egestas nisl nam varius aliquet id
								egestas. Tellus purus vitae risus leo viverra lectus mauris
								venenatis.{" "}
							</p>
						</div>
					</div>
					{/* button */}
					<div>
						<button className=" font-Segoe text-navbtncol border-4 border-navbtncol rounded-md w-[188px] h-[50px] cursor-pointer transition-transform hover:scale-110 duration-300 ease-in">
							READ MORE
						</button>
					</div>
				</div>
			</div>

			{/* section4 */}

			<div
				data-aos="fade-up"
				data-aos-duration="3000"
				className=" lg:flex md:grid grid gap-10 justify-center items-center px-8 md:px-12 lg:px-20 py-10 mx-auto">
				<div className=" lg:w-1/2 md:full h-full grid space-y-16 justify-center items-center">
					<div className=" flex justify-center items-center space-x-10">
						<div>
							<img src={require("../assets/Line24.png")} alt="" />
						</div>
						<div className="grid space-y-5">
							<div>
								<p className=" text-[32px]">40 YEARS QUESTION</p>
							</div>
							<div>
								<p>
									Lorem ipsum dolor sit amet consectetur. Quisque id semper
									elementum donec. Id in faucibus ornare suspendisse porttitor
									diam. Quis pulvinar enim suscipit ut. Tincidunt risus egestas
									rhoncus habitasse. Rhoncus justo condimentum orci feugiat.
									Libero aliquet lectus egestas nisl nam varius aliquet id
									egestas. Tellus purus vitae risus leo viverra lectus mauris
									venenatis.{" "}
								</p>
							</div>
						</div>
					</div>

					{/* button */}
					<div>
						<button className=" font-Segoe text-navbtncol border-4 border-navbtncol rounded-md w-[188px] h-[50px] cursor-pointer transition-transform hover:scale-110 duration-300 ease-in">
							READ MORE
						</button>
					</div>
				</div>
				<div className=" lg:w-1/2 md:full full">
					<img src={require("../assets/Frame8.png")} alt="" />
				</div>
			</div>

			{/* section5 Testimonial */}

			<div>
				<div
					className="bg-cover bg-no-repeat bg-center h-full w-full"
					style={{ backgroundImage: `url(${Testimonialbg})` }}>
					<div className=" bg-blue-500 opacity-90">
						<div
							data-aos="fade-down"
							data-aos-duration="3000"
							className=" text-white px-8 md:px-12 lg:px-20 py-20 space-y-20 mx-auto">
							<div className="grid justify-center items-center space-y-5">
								<div>
									<p className=" text-[16px] text-navbtncol font-public text-center">
										TESTIMONIALS
									</p>
								</div>
								<div>
									<p className=" text-[32px] font-public text-center">
										Hear Our Customers Speak
									</p>
								</div>
							</div>
							<div className=" grid justify-center space-y-4">
								<div className=" lg:flex md:grid grid bg-black opacity-75 w-full max-w-[1004px] gap-10 p-5 h-auto">
									<div className=" lg:w-2/5 md:w-full w-full flex lg:justify-between md:justify-center justify-center items-center">
										<div className=" flex items-center space-x-5">
											<div>
												<img
													className=" w-[100px] h-[100px]"
													src={require("../assets/Testimonialimg.png")}
													alt=""
												/>
											</div>
											<div className="grid">
												<p className=" text-[16px] font-nunito">Jhon Smith</p>
												<p className=" text-[16px] font-nunito">FinTech</p>
											</div>
										</div>

										<div>
											<img
												className=" w-auto h-[70px] lg:block md:hidden hidden"
												src={require("../assets/TestimonialLine.png")}
												alt=""
											/>
										</div>
									</div>
									<div className=" lg:w-3/5 md:w-full w-full">
										<p className=" font-nunito">
											Lorem ipsum dolor sit amet consectetur. Nisl egestas in
											morbi vel. Potenti magna eleifend risus bibendum id
											quisque. Cursus tellus gravida vulputate ac diam. Ultrices
											risus ut justo justo dolor ultrices sit vitae nisl. Cum
											duis at vulputate blandit tincidunt quis ornare. Lacus in
											erat lacus congue.
										</p>
									</div>
								</div>
								{/* slide dots */}
								<div className=" flex justify-center gap-3 items-center">
									<img
										className=" w-[10px] h-[10px]"
										src={require("../assets/Ellipse3.png")}
										alt=""
									/>
									<img
										className="w-[10px] h-[10px]"
										src={require("../assets/Ellipse4.png")}
										alt=""
									/>
									<img
										className="w-[10px] h-[10px]"
										src={require("../assets/Ellipse5.png")}
										alt=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* section6 Wide range service */}

			<div className=" grid justify-center items-center px-8 md:px-12 lg:px-20 gap-10  py-10 mx-auto">
				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className=" lg:flex md:grid grid justify-center items-center gap-5">
					<div className=" lg:w-2/5 md:w-full w-full flex items-center justify-between">
						<div className=" grid items-center space-y-5">
							<div>
								<p className=" text-[20px] text-navbtncol font-public">
									OUR WIDE RANGE OF SERVICES
								</p>
							</div>
							<div>
								<p className=" text-[32px] font-public">
									We Are Ready To Give You A New Experience{" "}
								</p>
							</div>
						</div>
						<div className=" grid">
							<img
								className=" h-[170px] w-auto lg:block md:hidden hidden"
								src={require("../assets/line.png")}
								alt=""
							/>
						</div>
					</div>
					<div className=" lg:w-3/5 md:w-full w-full">
						<p className=" text-[20px] font-public">
							Lorem ipsum dolor sit amet consectetur. Id in tempor dui non nunc
							leo. Imperdiet mi arcu ornare vehicula elit ullamcorper duis. Odio
							egestas ut est aliquet nullam tempus. Pretium donec euismod
							rhoncus est fusce. Mattis nibh scelerisque vel nisl nulla eget
							nunc orci. Tellus elit ut dui.
						</p>
					</div>
				</div>

				{/* cards */}

				<div data-aos="fade-left" data-aos-duration="3000">
					<ExamType />
				</div>
				<div className=" flex justify-end ">
					<div className=" flex items-center gap-3">
						<img
							className=" w-[40px] h-[40px] cursor-pointer"
							src={require("../assets/Arrowleft.png")}
							alt=""
						/>
						<img
							className=" w-[40px] h-[40px] cursor-pointer"
							src={require("../assets/Arrowright.png")}
							alt=""
						/>
					</div>
				</div>
			</div>

			{/* section7 */}

			<div className=" bg-landingpatnercol">
				<div
					data-aos="fade-right"
					data-aos-duration="3000"
					className="px-8 md:px-12 lg:px-20 py-20 space-y-20 text-white">
					<div className=" space-y-5">
						<div className=" flex justify-center items-center">
							<p className=" text-[32px] font-Segoe font-bold">Our Partners</p>
						</div>
						<div className=" flex justify-center items-center">
							<p className=" text-[24px] font-Segoe">
								Trusted by 100+ Top Companies
							</p>
						</div>
					</div>

					<div className="grid justify-center items-center">
						<div className=" lg:flex md:grid grid justify-center gap-5 items-center">
							<div>
								<img
									className=" w-[30px] h-[30px] cursor-pointer transition-transform hover:scale-110 duration-300 ease-in"
									src={require("../assets/Arrow1.png")}
									alt=""
								/>
							</div>

							<div className=" grid lg:relative left-24 lg:grid-cols-8 md:grid-cols-4 grid-cols-2 justify-center items-center gap-10 ">
								<div>
									<img
										className=" w-[100px] h-[100px]"
										src={require("../assets/AfTech.png")}
										alt=""
									/>
								</div>
								<div>
									<img
										className=" w-[58.5px] h-[100px]"
										src={require("../assets/Ninemobile.png")}
										alt=""
									/>
								</div>
								<div>
									<img
										className=" w-[100px] h-[100px]"
										src={require("../assets/NTA.png")}
										alt=""
									/>
								</div>
								<div>
									<img
										className=" w-[100px] h-[100px]"
										src={require("../assets/Superscreen.png")}
										alt=""
									/>
								</div>
								<div>
									<img
										className=" w-[100px] h-[100px]"
										src={require("../assets/MTN.png")}
										alt=""
									/>
								</div>
								<div>
									<img
										className=" w-[100px] h-[100px]"
										src={require("../assets/Airtel.png")}
										alt=""
									/>
								</div>
							</div>

							<div className=" flex justify-end items-end cursor-pointer transition-transform hover:scale-110 duration-300 ease-in">
								<img
									className=" w-[30px] h-[30px]"
									src={require("../assets/Arrow2.png")}
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* section8 Pricing */}

			<div className=" grid justify-center items-center space-y-10 px-8 md:px-12 lg:px-20 py-20">
				<div data-aos="fade-up" data-aos-duration="3000" className=" space-y-3">
					<p className=" font-public text-[16px] text-center text-navbtncol">
						OUR PRICING
					</p>
					<p className=" font-public text-[32px] text-center">
						Affordable{" "}
						<span className=" font-bold text-[32px] font-public">Pricing</span>{" "}
						Plans
					</p>
				</div>
				<div className=" flex justify-center items-center gap-4">
					<button className=" px-4 py-2 bg-landingpatnercol rounded-full text-white">
						Monthly
					</button>
					<button className=" text-landingpatnercol">Annualy</button>
					<div className=" flex">
						<img
							className=" w-[68.26px] h-[54.61px]"
							src={require("../assets/Vector.png")}
							alt=""
						/>
						<p className=" text-landingpatnercol">Get 20% OFF</p>
					</div>
				</div>

				{/* Pricing cards */}

				<div className=" grid lg:grid-cols-3 justify-between gap-5 ">
					{/* 1st card */}
					<div
						data-aos="fade-up"
						data-aos-duration="3000"
						className=" grid card shadow-md text-white justify-center w-full md:w-[480px] lg:max-w-[344px] h-auto bg-gradient-to-r from-footerbgcol to-landingpatnercol px-5 py-10 rounded-lg transition-transform hover:scale-110 duration-300 ease-in">
						<div className=" grid space-y-5">
							<div className="">
								<div>
									<p className=" font-inter text-[20px] font-sansation">
										Basic
									</p>
								</div>
								<div className=" flex">
									<p className=" text-[56px] font-bold font-Segoe">$10</p>
									<p className=" font-Segoe text-[16px] flex items-end pb-4 ">
										/month
									</p>
								</div>
							</div>

							<div>
								<img
									className=" w-[310px] h-auto"
									src={require("../assets/Pricingline.png")}
									alt=""
								/>
							</div>

							<div className=" space-y-5">
								<div className=" flex items-center gap-3">
									<img
										className=" w-[20px] h-[20px]"
										src={require("../assets/Priccheck.png")}
										alt=""
									/>
									<p className=" font-inter lg:text-[16px] md:text-[16px] text-[14px]">
										24/7 Online Support
									</p>
								</div>
								<div className=" flex items-center gap-3">
									<img
										className=" w-[20px] h-[20px]"
										src={require("../assets/Priccheck.png")}
										alt=""
									/>
									<p className=" font-inter lg:text-[16px] md:text-[16px] text-[14px]">
										Multiple Platforms
									</p>
								</div>
								<div className=" flex items-center gap-3">
									<img
										className=" w-[20px] h-[20px]"
										src={require("../assets/Priccheck.png")}
										alt=""
									/>
									<p className=" font-inter lg:text-[16px]  md:text-[16px] text-[14px]">
										Benefits
									</p>
								</div>
							</div>

							{/* button */}

							<div className=" flex justify-center">
								<button className=" bg-navbtncol  text-white w-[180px] h-[40px] font-nunito text-[16px] rounded-full">
									Start Plan Now
								</button>
							</div>
						</div>
					</div>

					{/* 2nd Card */}

					<div
						data-aos="fade-down"
						data-aos-duration="3000"
						className=" grid card shadow-md text-black justify-center w-full md:w-[480px] lg:max-w-[344px] h-auto bg-white px-5 py-10 rounded-lg transition-transform hover:scale-110 duration-300 ease-in">
						<div className=" grid space-y-5">
							<div className="">
								<div>
									<p className=" font-inter text-[20px] font-sansation">
										Avanced
									</p>
								</div>
								<div className=" flex">
									<p className=" text-[56px] font-bold font-Segoe">$30</p>
									<p className=" font-Segoe text-[16px] flex items-end pb-4 ">
										/month
									</p>
								</div>
							</div>

							<div>
								<img
									className=" w-[310px] h-auto"
									src={require("../assets/PricingLine21.png")}
									alt=""
								/>
							</div>

							<div className=" space-y-5">
								<div className=" flex items-center gap-3">
									<img
										className=" w-[20px] h-[20px]"
										src={require("../assets/Priccheck.png")}
										alt=""
									/>
									<p className=" font-inter lg:text-[16px] md:text-[16px] text-[14px]">
										24/7 Online Support
									</p>
								</div>
								<div className=" flex items-center gap-3">
									<img
										className=" w-[20px] h-[20px]"
										src={require("../assets/Priccheck.png")}
										alt=""
									/>
									<p className=" font-inter lg:text-[16px] md:text-[16px] text-[14px]">
										Multiple Platforms
									</p>
								</div>
								<div className=" flex items-center gap-3">
									<img
										className=" w-[20px] h-[20px]"
										src={require("../assets/Priccheck.png")}
										alt=""
									/>
									<p className=" font-inter lg:text-[16px]  md:text-[16px] text-[14px]">
										Benefits
									</p>
								</div>
								<div className=" flex items-center gap-3">
									<img
										className=" w-[20px] h-[20px]"
										src={require("../assets/Priccheck.png")}
										alt=""
									/>
									<p className=" font-inter lg:text-[16px]  md:text-[16px] text-[14px]">
										Benefits
									</p>
								</div>
							</div>

							{/* button */}

							<div className=" flex justify-center">
								<button className=" bg-landingpatnercol  text-white w-[180px] h-[40px] font-nunito text-[16px] rounded-full">
									Start Plan Now
								</button>
							</div>
						</div>
					</div>

					{/* 3rd Card */}

					<div
						data-aos="fade-up"
						data-aos-duration="3000"
						className=" grid card shadow-md text-white justify-center w-full md:w-[480px] lg:max-w-[344px] h-auto bg-gradient-to-r from-footerbgcol to-landingpatnercol px-5 py-10 rounded-lg transition-transform hover:scale-110 duration-300 ease-in">
						<div className=" grid space-y-5 ">
							<div className="">
								<div>
									<p className=" font-Poppins text-[20px] font-sansation">
										Avanced
									</p>
								</div>
								<div className=" flex">
									<p className=" text-[56px] font-bold font-Segoe">$60</p>
									<p className=" font-Segoe text-[16px] flex items-end pb-4 ">
										/month
									</p>
								</div>
							</div>

							<div>
								<img
									className=" w-[310px] h-auto"
									src={require("../assets/Pricingline.png")}
									alt=""
								/>
							</div>

							<div className=" space-y-5">
								<div className=" flex items-center gap-3">
									<img
										className=" w-[20px] h-[20px]"
										src={require("../assets/Priccheck.png")}
										alt=""
									/>
									<p className=" font-inter lg:text-[16px] md:text-[16px] text-[14px]">
										24/7 Online Support
									</p>
								</div>
								<div className=" flex items-center gap-3">
									<img
										className=" w-[20px] h-[20px]"
										src={require("../assets/Priccheck.png")}
										alt=""
									/>
									<p className=" font-inter lg:text-[16px] md:text-[16px] text-[14px]">
										Multiple Platforms
									</p>
								</div>
								<div className=" flex items-center gap-3">
									<img
										className=" w-[20px] h-[20px]"
										src={require("../assets/Priccheck.png")}
										alt=""
									/>
									<p className="font-inter lg:text-[16px]  md:text-[16px] text-[14px]">
										Benefits
									</p>
								</div>
								<div className=" flex items-center gap-3">
									<img
										className=" w-[20px] h-[20px]"
										src={require("../assets/Priccheck.png")}
										alt=""
									/>
									<p className=" font-inter lg:text-[16px]  md:text-[16px] text-[14px]">
										Benefits
									</p>
								</div>
							</div>

							{/* button */}

							<div className=" flex justify-center">
								<button className=" bg-navbtncol  text-white w-[180px] h-[40px] font-nunito text-[16px] rounded-full">
									Start Plan Now
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Footer */}
			<Firstfooter />
		</div>
	);
};

export default LandingPage;
