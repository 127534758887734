import React, { useState, useEffect } from "react";
import ExampageNavbar from "../navbar/[exampagenavbar]";
import NavContact from "../navbar/[navcontact]";
import Firstfooter from "../../components/footers/Firstfooter";
import SubjectYears from "../../components/subject-and-year/SubjectYears";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ExamLandingpage = () => {
	let { state } = useLocation(),
		navigate = useNavigate(),
		{ auth } = useSelector(s => s),
		[data, setData] = useState({});

	useEffect(() => {
		if (!state) return navigate(-1);
		if (!auth?.isAuth) return navigate("/");
	}, [auth?.isAuth, state, navigate]);

	return (
		<div className=" mx-auto overflow-x-hidden">
			<NavContact />
			<ExampageNavbar />
			<div className="px-8 md:px-12 lg:px-20 py-10 space-y-20">
				<div>
					<p className=" font-Segoe text-[24px] uppercase">
						{state?.title} QUESTIONS
					</p>
				</div>
				<div className=" grid justify-center items-center">
					<div>
						<img
							className=" w-[500px] h-[501.11px]"
							src={require("../../assets/Examhero.png")}
							alt=""
						/>
					</div>
				</div>
			</div>

			{/* section2 Available Subject */}
			<div>
				<SubjectYears examType={state?._id} data={data} setData={setData} />
			</div>
			{data?.year && data?.subject && (
				<div className=" flex justify-center items-center py-10">
					<Link
						to={`/exam-selection/${state?.title?.replace("/", "_")}`}
						state={{ ...state, ...data }}
						className="lg:w-[405px] md:w-[205px] w-[170px] lg:h-[81px] md:h-[71px] h-[61px] font-Segoe text-white bg-navbtncol rounded-md flex justify-center items-center">
						START PRACTICING
					</Link>
				</div>
			)}

			{/* Footer */}

			<Firstfooter />
		</div>
	);
};

export default ExamLandingpage;
