import React from "react";
import { useState } from "react";

// ANSWER CARD
const Card = ({
  question_no,
  question,
  answer_a,
  answer_b,
  answer_c,
  answer_d,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const handleAnswerClick = (answer) => {
    setSelectedAnswer(answer);
  };

  return (
    <div className="grid gap-5">
      <div>
        <p>{question_no}</p>
      </div>
      <div className="card p-5 shadow-md grid items-center gap-5">
        <div>{question}</div>
        {/* answers */}
        <div className="grid gap-3">
          <div className="flex items-center px-5">
            {/* answer A */}
            <div
              className={`bg-exampageseccol px-2 w-full border gap-2 border-l-4 h-[48px] flex items-center ${
                selectedAnswer === "A" ? "border-purple-500" : ""
              }`}
              onClick={() => handleAnswerClick("A")}
            >
              <input type="radio" />
              A. {answer_a}
            </div>
          </div>
          <div className="flex items-center px-5">
            {/* Answer B */}
            <div
              className={`bg-exampageseccol gap-2 border-l-4 px-2 border w-full h-[48px] flex items-center ${
                selectedAnswer === "B" ? "border-purple-500" : ""
              }`}
              onClick={() => handleAnswerClick("B")}
            >
              <input type="radio" name="" id="" />
              B. {answer_b}
            </div>
          </div>
          <div className="flex items-center px-5">
            {/* Answer C */}
            <div
              className={`bg-exampageseccol border gap-2 border-l-4 px-2 w-full h-[48px] flex items-center ${
                selectedAnswer === "C" ? "border-purple-500" : ""
              }`}
              onClick={() => handleAnswerClick("C")}
            >
              <input type="radio" />
              C. {answer_c}
            </div>
          </div>
          <div className="flex items-center px-5">
            {/* Answer D */}
            <div
              className={`bg-exampageseccol border gap-2 border-l-4 px-2 w-full h-[48px] flex items-center ${
                selectedAnswer === "D" ? "border-purple-500" : ""
              }`}
              onClick={() => handleAnswerClick("D")}
            >
              <input type="radio" />
              D. {answer_d}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SecondQuestion = () => {
  const cardsData = [
    {
      question_no: "Question 1",
      question: "What is the capital of France?",
      answer_a: "Paris",
      answer_b: "Berlin",
      answer_c: "Madrid",
      answer_d: "Rome",
    },
    {
      question_no: "Question 2",
      question: "Which planet is known as the Red Planet?",
      answer_a: "Venus",
      answer_b: "Mars",
      answer_c: "Jupiter",
      answer_d: "Saturn",
    },
  ];

  return (
    <div className="grid items-center lg:px-28 gap-10">
      {cardsData.map((card, index) => (
        <Card key={index} {...card} />
      ))}
    </div>
  );
};

export default SecondQuestion;
