import React from "react";
import { Link } from "react-router-dom";

const SubmintAlert = ({ message, onClose, onConfirm }) => {
  return (
		<div className=" alert-container">
			<div className="alert-content bg-white shadow-md p-10">
				<div
					className="close-icon flex justify-end cursor-pointer"
					onClick={onClose}>
					X
				</div>

				<div className=" flex justify-center items-center gap-2 animate-bounce py-4">
					<div className=" w-5 h-5 rounded-full bg-exampageseccol"></div>
					<div className=" w-5 h-5 rounded-full bg-exampagetestcol"></div>
					<div className=" w-5 h-5 rounded-full bg-jambpagetesultcol"></div>
					<div className=" w-5 h-5 rounded-full bg-landingpatnercol"></div>
				</div>
				<p className=" w-[200px] text-center py-4">{message}</p>
				<div className=" justify-center items-center flex gap-3">
					<button onClick={onClose} className=" bg-gray-200 w-[100px] h-[41px]">
						Cancel
					</button>
					<Link
						to="#"
						onClick={onConfirm}
						className=" flex justify-center items-center text-white bg-navbtncol w-[100px] h-[41px]">
						Submit
					</Link>
				</div>
			</div>
		</div>
	);
};

export default SubmintAlert;
