import React from "react";

export let ansArr = ["A", "B", "C", "D"];

// ANSWER CARD
const Card = ({ rest, index, setIsSelected, isSelected, answerArr }) => {
	return (
		<div className="grid gap-5">
			<div>
				<p>Question {index + 1}</p>
			</div>
			<div className="card p-5 shadow-md grid items-center gap-5">
				<div>{rest?.question}</div>
				{rest?.file && (
					<div className="flex flex-col items-center gap-4 mt-8">
						<img
							src={rest?.file?.url || null}
							alt=""
							className="h-80 w-auto rounded-sm border"
						/>
					</div>
				)}
				{/* answers */}
				<div className="grid gap-3">
					{rest?.options?.map((it, ind) => (
						<div
							key={ind}
							onClick={() =>
								setIsSelected({ answer: it?._id, option: it?.option })
							}
							className="flex items-center px-5 cursor-pointer">
							<div
								className={`bg-exampageseccol px-2 w-full rounded-lg border ${
									!it?.file ? "h-[48px] flex items-center" : "py-3"
								} ${
									isSelected?.answer === it?._id ||
									answerArr?.find(it => it?.question === rest?._id)?.option ===
										it?._id
										? "border-purple-500 bg-purple-500"
										: ""
								}`}>
								<p>
									{ansArr?.[ind]}. {it?.option}
								</p>
								{it?.file && (
									<div className="flex flex-col items-center gap-4">
										<img
											src={it?.file?.url || null}
											alt=""
											className="h-52 w-52 rounded-sm border"
										/>
									</div>
								)}
							</div>
						</div>
					))}

					{/* <div className="flex items-center px-5">
						<div
							className={`bg-exampageseccol px-2 border rounded-lg w-full h-[48px] flex items-center ${
								selectedAnswer === "B" ? "border-purple-500" : ""
							}`}
							onClick={() => handleAnswerClick("B")}>
							B. {answer_b}
						</div>
					</div>
					<div className="flex items-center px-5">
						<div
							className={`bg-exampageseccol border rounded-lg px-2 w-full h-[48px] flex items-center ${
								selectedAnswer === "C" ? "border-purple-500" : ""
							}`}
							onClick={() => handleAnswerClick("C")}>
							C. {answer_c}
						</div>
					</div>
					<div className="flex items-center px-5">
						<div
							className={`bg-exampageseccol border rounded-lg px-2 w-full h-[48px] flex items-center ${
								selectedAnswer === "D" ? "border-purple-500" : ""
							}`}
							onClick={() => handleAnswerClick("D")}>
							D. {answer_d}
						</div>
					</div> */}
				</div>
			</div>
		</div>
	);
};

const QuestionAndAnswer = ({
	setCurrentPage,
	currentPage,
	questions,
	setIsSelected,
	isSelected,
	answerArr,
}) => {
	// const cardsData = [
	//   {
	//     question_no: "Question 1",
	//     question: "What is the capital of France?",
	//     answer_a: "Paris",
	//     answer_b: "Berlin",
	//     answer_c: "Madrid",
	//     answer_d: "Rome",
	//   },
	//   {
	//     question_no: "Question 2",
	//     question: "Which planet is known as the Red Planet?",
	//     answer_a: "Venus",
	//     answer_b: "Mars",
	//     answer_c: "Jupiter",
	//     answer_d: "Saturn",
	//   },
	// ];

	const itemsPerPage = 1; // Change this to the number of questions per page

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const visibleCards = questions?.slice(startIndex, endIndex);

	return (
		<div className="grid items-center lg:px-28 gap-10">
			{visibleCards?.map((card, index) => (
				<Card
					key={index}
					rest={card}
					index={index}
					setIsSelected={setIsSelected}
					isSelected={isSelected}
					answerArr={answerArr}
				/>
			))}
		</div>
	);
};

export default QuestionAndAnswer;
