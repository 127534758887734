import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PageRender from "./PageRender";
import Home from "./screens/home.jsx";
import { useSelector } from "react-redux";
import Exampage from "./screens/exam-page";

const Routers = () => {
	const { auth } = useSelector(state => state);

	return (
		<>
			<ToastContainer position="bottom-center" />
			<Routes>
				<Route path="/" element={auth?.isAuth ? <Exampage /> : <Home />} />
				<Route path="/:page" element={<PageRender />} />
				<Route path="/:page/:id" element={<PageRender />} />
				<Route path="/:page/:id/:step" element={<PageRender />} />
			</Routes>
		</>
	);
};

export default Routers;
