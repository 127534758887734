import React from "react";
import ExampageNavbar from "../navbar/[exampagenavbar]";
import NavContact from "../navbar/[navcontact]";
import Firstfooter from "../../components/footers/Firstfooter";
import SubjectYears from "../../components/subject-and-year/SubjectYears";
import { Link } from "react-router-dom";

const NecoPage = () => {
  return (
    <div className=" mx-auto">
      <NavContact />
      <ExampageNavbar />
      <div className="px-8 md:px-12 lg:px-20 py-10 space-y-20">
        <div>
          <p className=" font-Segoe text-[24px]">NECO QUESTIONS</p>
        </div>
        <div className=" grid justify-center items-center">
          <div>
            <img
              className=" w-[500px] h-[501.11px]"
              src={require("../../assets/Waecpage.png")}
              alt=""
            />
          </div>
        </div>
        <div className=" flex justify-center items-center">
          <img
            className=" w-[411px]"
            src={require("../../assets/Pick_a_year.png")}
            alt=""
          />
        </div>
      </div>

      {/* section2 Available Subject */}
      <div>
        <SubjectYears />
      </div>
      <div className=" flex justify-center items-center py-10">
        <Link
          to="/neco-chemistry"
          className="lg:w-[405px] md:w-[205px] w-[170px] lg:h-[81px] md:h-[71px] h-[61px] font-Segoe text-white bg-navbtncol rounded-md flex justify-center items-center"
        >
          START PRACTICING
        </Link>
      </div>

      {/* Footer */}

      <Firstfooter />
    </div>
  );
};

export default NecoPage;
