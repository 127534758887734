import React from "react";
import { useState } from "react";
import NavContact from "../navbar/[navcontact]";
import ExampageNavbar from "../navbar/[exampagenavbar]";
import Secfooter from "../../components/footers/Secfooter";
import AnswerButton from "../../components/answer-buttons/AnswerButton";
import { Link } from "react-router-dom";
import SubmintAlert from "../../components/submit-alert";
import SecondQuestion from "../../components/sec-question-and-answer/SecondQuestion";

const NecoChemistry = () => {
  const [showAlert, setShowAlert] = useState(false);

  const handleShowAlert = () => {
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleConfirm = () => {
    alert("submitted successfully!");

    handleCloseAlert();
  };

  return (
    <div className=" mx-auto overflow-x-hidden">
      <NavContact />
      <ExampageNavbar />
      <div className=" grid px-8 md:px-12 lg:px-20 gap-10">
        <div>
          <p className=" text-[24px] font-Segoe">
            2022 CHEMISTRY PAST QUESTIONS
          </p>
        </div>
        {/* Question card */}
        <SecondQuestion />

        <div className=" flex justify-end items-center lg:px-28">
          <Link
            to="/waec-mathematics"
            className=" flex items-center cursor-pointer w-[194px] h-[50px] justify-end bg-exampageseccol rounded-full"
          >
            <p>Next</p>
            <img
              className=" w-[50px] h-[50px]"
              src={require("../../assets/Nextimg.png")}
              alt=""
            />
          </Link>
        </div>
        <div className=" lg:px-20">
          <div>
            <p className=" font-Segoe font-bold">Jump to Question:</p>
          </div>
          {/* answer card */}
          <AnswerButton />
          <div className=" flex justify-center py-10">
            <Link
              onClick={handleShowAlert}
              className=" w-[285px] h-[51px] bg-exampageseccol font-Segoe flex justify-center items-center"
            >
              SUBMIT
            </Link>
            {showAlert && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <SubmintAlert
                  // the card message
                  message="Are you sure you want to submit now? Action cannot be undone."
                  onClose={handleCloseAlert}
                  onConfirm={handleConfirm}
                  alertClass="bg-white p-6 rounded shadow-md"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* footer */}
      <Secfooter />
    </div>
  );
};

export default NecoChemistry;
