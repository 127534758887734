import React from "react";

// THE ANSWER BUTTONS
const Card = ({ answer_no, onAnswerButtonClick }) => {
	const handleAnswerClick = () => {
		onAnswerButtonClick(1 + answer_no);
	};
	return (
		<div
			onClick={handleAnswerClick}
			className=" transition-transform hover:scale-110 duration-300 ease-in">
			<div className="px-2 py-2 cursor-pointer bg-gray-50 font-alata text-[14px] grid lg:justify-center md:justify-start justify-start items-center">
				{answer_no + 1}
			</div>
		</div>
	);
};

const AnswerButton = ({ onAnswerButtonClick, questions }) => {
	// BUTTONS
	// const cardData = [
	//   {
	//     answer_no: 1,
	//   },
	//   {
	//     answer_no: 2,
	//   },
	//   {
	//     answer_no: 3,
	//   },
	//   {
	//     answer_no: 4,
	//   },
	//   {
	//     answer_no: 5,
	//   },
	//   {
	//     answer_no: 6,
	//   },
	//   {
	//     answer_no: 7,
	//   },
	//   {
	//     answer_no: 8,
	//   },
	//   {
	//     answer_no: 9,
	//   },
	//   {
	//     answer_no: 10,
	//   },
	//   {
	//     answer_no: 11,
	//   },
	//   {
	//     answer_no: 12,
	//   },
	//   {
	//     answer_no: 13,
	//   },
	//   {
	//     answer_no: 14,
	//   },
	//   {
	//     answer_no: 15,
	//   },
	//   {
	//     answer_no: 16,
	//   },
	//   {
	//     answer_no: 17,
	//   },
	//   {
	//     answer_no: 18,
	//   },
	//   {
	//     answer_no: 19,
	//   },
	//   {
	//     answer_no: 20,
	//   },
	//   {
	//     answer_no: 21,
	//   },
	//   {
	//     answer_no: 22,
	//   },
	//   {
	//     answer_no: 23,
	//   },
	//   {
	//     answer_no: 24,
	//   },
	//   {
	//     answer_no: 25,
	//   },
	//   {
	//     answer_no: 26,
	//   },
	//   {
	//     answer_no: 27,
	//   },
	//   {
	//     answer_no: 28,
	//   },
	//   {
	//     answer_no: 29,
	//   },
	//   {
	//     answer_no: 30,
	//   },
	// ];
	return (
		<div className="grid lg:grid-cols-11 lg:gap-10 md:gap-10 gap-5 md:grid-cols-7 py-5 grid-cols-4">
			{questions?.map((answer, index) => (
				<Card
					key={index}
					answer_no={index}
					onAnswerButtonClick={onAnswerButtonClick}
				/>
			))}
		</div>
	);
};

export default AnswerButton;
