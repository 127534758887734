import React from "react";
import { useState } from "react";
import NavContact from "../navbar/[navcontact]";
import ExampageNavbar from "../navbar/[exampagenavbar]";
import Secfooter from "../../components/footers/Secfooter";

const Card = ({
  question_no,
  question,
  answer_a,
  answer_b,
  answer_c,
  answer_d,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const handleAnswerClick = (answer) => {
    setSelectedAnswer(answer);
  };

  return (
    <div className="grid gap-5">
      <div>
        <p>{question_no}</p>
      </div>
      <div className="card p-5 shadow-md grid items-center gap-5">
        <div>{question}</div>
        {/* answers */}
        <div className="grid gap-3">
          <div className="flex items-center px-5">
            {/* answer A */}
            <div
              className={`bg-exampageseccol px-2 w-full rounded-lg border h-[48px] flex items-center ${
                selectedAnswer === "A" ? "border-purple-500" : ""
              }`}
              onClick={() => handleAnswerClick("A")}
            >
              A. {answer_a}
            </div>
          </div>
          <div className="flex items-center px-5">
            {/* Answer B */}
            <div
              className={`bg-exampageseccol px-2 border rounded-lg w-full h-[48px] flex items-center ${
                selectedAnswer === "B" ? "border-purple-500" : ""
              }`}
              onClick={() => handleAnswerClick("B")}
            >
              B. {answer_b}
            </div>
          </div>
          <div className="flex items-center px-5">
            {/* Answer C */}
            <div
              className={`bg-exampageseccol border rounded-lg px-2 w-full h-[48px] flex items-center ${
                selectedAnswer === "C" ? "border-purple-500" : ""
              }`}
              onClick={() => handleAnswerClick("C")}
            >
              C. {answer_c}
            </div>
          </div>
          <div className="flex items-center px-5">
            {/* Answer D */}
            <div
              className={`bg-exampageseccol border rounded-lg px-2 w-full h-[48px] flex items-center ${
                selectedAnswer === "D" ? "border-purple-500" : ""
              }`}
              onClick={() => handleAnswerClick("D")}
            >
              D. {answer_d}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Answers

const Answers = ({ answer_no }) => {
  return (
    <div className=" transition-transform hover:scale-110 duration-300 ease-in">
      <div className="px-2 py-2 cursor-pointer bg-gray-50 font-alata text-[14px] grid lg:justify-center md:justify-start justify-start items-center">
        {answer_no}
      </div>
    </div>
  );
};

const BioThirdPage = () => {
  const cardsData = [
    {
      question_no: "Question 5",
      question: "What is the capital of France?",
      answer_a: "Paris",
      answer_b: "Berlin",
      answer_c: "Madrid",
      answer_d: "Rome",
    },
    {
      question_no: "Question 6",
      question: "Which planet is known as the Red Planet?",
      answer_a: "Venus",
      answer_b: "Mars",
      answer_c: "Jupiter",
      answer_d: "Saturn",
    },
  ];

  const answerData = [
    {
      answer_no: 1,
    },
    {
      answer_no: 2,
    },
    {
      answer_no: 4,
    },
    {
      answer_no: 5,
    },
    {
      answer_no: 6,
    },
    {
      answer_no: 7,
    },
    {
      answer_no: 8,
    },
    {
      answer_no: 9,
    },
    {
      answer_no: 10,
    },
    {
      answer_no: 11,
    },
    {
      answer_no: 12,
    },
    {
      answer_no: 13,
    },
    {
      answer_no: 14,
    },
    {
      answer_no: 15,
    },
    {
      answer_no: 16,
    },
    {
      answer_no: 17,
    },
    {
      answer_no: 18,
    },
    {
      answer_no: 19,
    },
    {
      answer_no: 20,
    },
    {
      answer_no: 21,
    },
    {
      answer_no: 22,
    },
    {
      answer_no: 23,
    },
    {
      answer_no: 24,
    },
    {
      answer_no: 25,
    },
    {
      answer_no: 26,
    },
    {
      answer_no: 27,
    },
    {
      answer_no: 28,
    },
    {
      answer_no: 29,
    },
    {
      answer_no: 30,
    },
  ];

  return (
    <div className=" mx-auto">
      <NavContact />
      <ExampageNavbar />
      <div className=" grid px-8 md:px-12 lg:px-20 gap-10">
        <div>
          <p className=" text-[24px] font-Segoe">2012 BIOLOGY PAST QUESTIONS</p>
        </div>
        <div className="grid items-center lg:px-28 gap-10">
          {cardsData.map((card, index) => (
            <Card key={index} {...card} />
          ))}
        </div>

        <div className=" flex justify-between items-center lg:px-28">
          <button className=" flex items-center cursor-pointer lg:w-[194px] md:w-[164px] w-[120px] h-[50px] justify-start bg-exampageseccol rounded-full">
            <img
              className=" w-[50px] h-[50px]"
              src={require("../../assets/Prev.png")}
              alt=""
            />
            <p>Previous</p>
          </button>
          <button className=" flex items-center cursor-pointer lg:w-[194px] md:w-[164px] w-[120px] h-[50px] justify-end bg-exampageseccol rounded-full">
            <p>Next</p>
            <img
              className=" w-[50px] h-[50px]"
              src={require("../../assets/Nextimg.png")}
              alt=""
            />
          </button>
        </div>
        <div className=" lg:px-20">
          <div>
            <p className=" font-Segoe font-bold">Jump to Question:</p>
          </div>
          <div className="grid lg:grid-cols-11 lg:gap-10 md:gap-10 gap-5 md:grid-cols-7 py-5 grid-cols-4">
            {answerData.map((answer, index) => (
              <Answers key={index} {...answer} />
            ))}
          </div>
          <div className=" flex justify-center py-10">
            <button className=" w-[285px] h-[51px] bg-exampageseccol font-Segoe">
              SUBMIT
            </button>
          </div>
        </div>
      </div>

      {/* footer */}

      <Secfooter />
    </div>
  );
};

export default BioThirdPage;
