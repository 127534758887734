import React from "react";
import NavContact from "../navbar/[navcontact]";
import ExampageNavbar from "../navbar/[exampagenavbar]";
import Secfooter from "../../components/footers/Secfooter";
import { Link } from "react-router-dom";

const ReviewAnswers = () => {
  return (
    <div className=" mx-auto">
      <NavContact />
      <ExampageNavbar />

      <div className="px-8 md:px-12 lg:px-20 py-5 grid h-72 w-full justify-center items-center"></div>
      <div className="px-8 md:px-12 lg:px-20 py-5 flex justify-center items-center ">
        <Link
          to="/exam-page"
          className=" w-[309px] h-[65px] rounded-full flex justify-center items-center bg-exampagetestcol text-white"
        >
          Back to Practice
        </Link>
      </div>

      {/* Footer */}
      <Secfooter />
    </div>
  );
};

export default ReviewAnswers;
