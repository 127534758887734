import { ClipLoader } from "react-spinners";
import "./loading.css";

const BUTTON_TYPES = {
	primary: "bg-black text-white",
	primaryOutline: "border-2 border-[#2A72A8] text-main",
	secondary: "bg-[#F72585] text-white",
	tetiary: "border-2 border-main text-main",
};

export const Buttons = ({
	children,
	loading,
	buttonType,
	width,
	style,
	title,
	loadCss,
	disabled,
	icon,
	type,
	...restProps
}) => {
	return (
		<div>
			<button
				type={type || "button"}
				disabled={loading || disabled}
				className={`text-sm p-2 px-8 rounded-lg flex items-center gap-2 justify-center ${
					width || "w-52"
				} ${style || ""} ${BUTTON_TYPES[buttonType]}`}
				{...restProps}>
				{icon && <span>{icon}</span>}
				{children}
				<span className={loading ? "me-2" : ""}>
					{title ? title : "submit"}
				</span>
				{loading && (
					<ClipLoader color={loadCss ? loadCss : "white"} size={16} />
				)}
			</button>
		</div>
	);
};

export const LoadingLoader = () => {
	return (
		<div className="loader">
			<div className="hexagon" aria-label="Animated hexagonal ripples">
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
			</div>
			<div className="tw-flex tw-items-center tw-justify-center">
				<p aria-label="Loading" className="text-center">
					Loading
				</p>
			</div>
		</div>
	);
};
