import React from "react";

import NavContact from "../navbar/[navcontact]";
import ExampageNavbar from "../navbar/[exampagenavbar]";
import Secfooter from "../../components/footers/Secfooter";
import AnswerButton from "../../components/answer-buttons/AnswerButton";
import QuestionAndAnswer from "../../components/question-and-answer/QuestionAndAnswer";

const BioFirstPage = () => {
  return (
    <div className=" mx-auto">
      <NavContact />
      <ExampageNavbar />
      <div className=" grid px-8 md:px-12 lg:px-20 gap-10">
        <div>
          <p className=" text-[24px] font-Segoe">2012 BIOLOGY PAST QUESTIONS</p>
        </div>
        {/* Question card */}
        <QuestionAndAnswer />

        <div className=" flex justify-end items-center lg:px-28">
          <button className=" flex items-center cursor-pointer w-[194px] h-[50px] justify-end bg-exampageseccol rounded-full">
            <p>Next</p>
            <img
              className=" w-[50px] h-[50px]"
              src={require("../../assets/Nextimg.png")}
              alt=""
            />
          </button>
        </div>
        <div className=" lg:px-20">
          <div>
            <p className=" font-Segoe font-bold">Jump to Question:</p>
          </div>
          {/* answer card */}
          <AnswerButton />
          <div className=" flex justify-center py-10">
            <button className=" w-[285px] h-[51px] bg-exampageseccol font-Segoe">
              SUBMIT
            </button>
          </div>
        </div>
      </div>

      {/* footer */}
      <Secfooter />
    </div>
  );
};

export default BioFirstPage;
