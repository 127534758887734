import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Secfooter = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className=" bg-footerbgcol text-white">
      <div
        data-aos="fade-left"
        data-aos-duration="3000"
        className=" px-8 md:px-12 lg:px-20 py-10 space-y-10"
      >
        <div className=" lg:flex md:flex grid lg:space-y-0 md:space-y-0 space-y-5 justify-between">
          <div className="grid space-y-4">
            <div>
              <img
                className=" w-[150px] h-auto"
                src={require("../../assets/Logo.png")}
                alt=""
              />
            </div>
            <div className=" space-y-2">
              <p className=" font-sansation text-[13px]">Address here</p>
              <p className=" font-sansation text-[13px] w-[122px]">
                Lorem ipsum dolor sit amet consectetur. Eu ut egestas elit ut
                orci. Bibendum lobortis amet.
              </p>
            </div>
          </div>
          <div className="grid space-y-2">
            <p className=" font-sansation text-[15px] font-bold">Quick Links</p>
            <ul className=" space-y-3">
              <li className="font-sansation text-[12px]">About</li>
              <li className="font-sansation text-[12px]">Contact</li>
              <li className="font-sansation text-[12px]">Quick Links</li>
            </ul>
          </div>
          <div className="grid space-y-2">
            <p className="font-sansation text-[15px] font-bold">Explore</p>
            <ul className=" space-y-5">
              <li className="font-sansation text-[12px]">Primary</li>
              <li className="font-sansation text-[12px]">Secondary</li>
              <li className="font-sansation text-[12px]">Pre-Varsity</li>
            </ul>
          </div>
          <div className="grid space-y-2">
            <p className="font-sansation text-[15px] font-bold">Contact Us</p>
            <ul className=" space-y-5">
              <li className="font-sansation text-[12px]">+234 800 000 0000</li>
              <li className="font-sansation text-[12px]">+234 704 330 3000</li>
              <li className="font-sansation text-[12px]">
                info@screenclass.com
              </li>
            </ul>
          </div>
          <div className=" space-y-3">
            <p className="font-sansation text-[15px] font-bold">
              Connect with us
            </p>

            <div className="flex items-center gap-3">
              <img
                className=" w-[24.25px] h-[23.64px]"
                src={require("../../assets/Instagram.png")}
                alt=""
              />
              <img
                className=" w-[24.25px] h-[23.64px]"
                src={require("../../assets/Twitter.png")}
                alt=""
              />
              <img
                className=" w-[24.25px] h-[23.64px]"
                src={require("../../assets/Facebook.png")}
                alt=""
              />
            </div>
          </div>
        </div>

        <div className=" grid items-center justify-center">
          <img
            className=" w-full"
            src={require("../../assets/footer.png")}
            alt=""
          />
        </div>
        <div className=" grid justify-center items-center">
          <p>
            Copyright © 2023. © 2023 - Screenclass Learning Management System
            Inc.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Secfooter;
