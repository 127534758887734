import React from "react";
import Cardimg1 from "../../assets/Cardimg1.png";
import Cardimg2 from "../../assets/Cardimg2.png";
import Cardimg3 from "../../assets/Cardimg3.png";
import Cardimg4 from "../../assets/Cardimg4.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Card = ({ imageSrc, text, buttonText, to, cardImg, ...rest }) => {
	return (
		<div className="w-[290px] md:w-full lg:max-w-[220px] h-auto rounded overflow-hidden shadow-lg m-4 transition-transform hover:scale-110 duration-300 ease-in">
			<img
				src={rest?.image?.url || cardImg}
				alt={rest?.title}
				className="w-full h-60 rounded-md"
			/>
			<div className="px-6 py-2">
				<div className="text-[14px] font-Poppins uppercase">{rest?.title}</div>
			</div>
			<div className=" text-[16px] py-5">
				<Link
					to={"/exam-selection"}
					state={rest}
					className=" text-navbtncol font-bold py-2 px-4">
					GET STARTED
				</Link>
			</div>
		</div>
	);
};

const ExamType = () => {
	const cardData = [
		{
			imageSrc: Cardimg1,
			text: "JAMB QUESTONS",
			buttonText: "GET STARTED",
			to: "/jamb-page",
		},
		{
			imageSrc: Cardimg2,
			text: "NECO QUESTIONS",
			buttonText: "GET STARTED",
			to: "/neco-page",
		},
		{
			imageSrc: Cardimg3,
			text: "WAEC QUESTIONS",
			buttonText: "GET STARTED",
			to: "/waec-page",
		},
		{
			imageSrc: Cardimg4,
			text: "GENERAL QUESTIONS",
			buttonText: "GET STARTED",
			to: "/genetal-question",
		},
		{
			imageSrc: Cardimg1,
			text: "JAMB QUESTONS",
			buttonText: "GET STARTED",
			to: "/jamb-page",
		},
		{
			imageSrc: Cardimg2,
			text: "NECO QUESTIONS",
			buttonText: "GET STARTED",
			to: "/neco-page",
		},
		{
			imageSrc: Cardimg3,
			text: "WAEC QUESTIONS",
			buttonText: "GET STARTED",
			to: "/waec-page",
		},
		{
			imageSrc: Cardimg4,
			text: "GENERAL QUESTIONS",
			buttonText: "GET STARTED",
			to: "/genetal-question",
		},
	];

	let { examType } = useSelector(s => s);

	return (
		<div
			style={{
				padding: 0,
			}}
			className="grid lg:grid-cols-4 md:grid-cols-2 gap-8  justify-center items-center">
			{examType?.data?.docs?.map((card, index) => (
				<Card
					key={index}
					{...card}
					cardImg={cardData?.[index % cardData?.length]?.imageSrc}
				/>
			))}
		</div>
	);
};

export default ExamType;
