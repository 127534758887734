import React, { useEffect, useState } from "react";
import Leaderboard from "../../assets/Leaderboard.png";
import NavContact from "../navbar/[navcontact]";
import ExampageNavbar from "../navbar/[exampagenavbar]";
import LeaderboardRect from "../../assets/LeaderboardRect.png";
import User1 from "../../assets/User1.png";
import User6 from "../../assets/User6.png";
import Firstfooter from "../../components/footers/Firstfooter";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector, useDispatch } from "react-redux";
import { LoadingLoader } from "../../components/button/button";
import { manageLeaderboard } from "../../data/Reducers/LeaderboardReducer";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const LeaderBoard = () => {
	useEffect(() => {
		AOS.init();
	}, []);
	const data = [
		{
			number: 4,
			image: User1,
			userName: "Username",
			phoneNumber: "+234901112223",
			email: "username@gmail.com",
			coin: "500coins",
			amount: 200,
		},
		{
			number: 5,
			image: User1,
			userName: "Username",
			phoneNumber: "+234901112223",
			email: "username@gmail.com",
			coin: "500coins",
			amount: 200,
		},
		{
			number: 6,
			image: User6,
			userName: "Username (You)",
			phoneNumber: "+234901112223",
			email: "username@gmail.com",
			coin: "500coins",
			amount: 200,
		},
		{
			number: 7,
			image: User1,
			userName: "Username",
			phoneNumber: "+234901112223",
			email: "username@gmail.com",
			coin: "500coins",
			amount: 200,
		},
		{
			number: 8,
			image: User1,
			userName: "Username",
			phoneNumber: "+234901112223",
			email: "username@gmail.com",
			coin: "500coins",
			amount: 200,
		},
		{
			number: 9,
			image: User6,
			userName: "Username",
			phoneNumber: "+234901112223",
			email: "username@gmail.com",
			coin: "500coins",
			amount: 200,
		},
		{
			number: 10,
			image: User1,
			userName: "Username",
			phoneNumber: "+234901112223",
			email: "username@gmail.com",
			coin: "500coins",
			amount: 200,
		},
		{
			number: 11,
			image: User1,
			userName: "Username",
			phoneNumber: "+234901112223",
			email: "username@gmail.com",
			coin: "500coins",
			amount: 200,
		},

		// Add more objects as needed
	];

	let [type, setType] = useState("day");

	let { leaderboard, auth } = useSelector(s => s),
		[state, setState] = useState(null),
		dispatch = useDispatch(),
		navigate = useNavigate();

	useEffect(() => {
		setState(
			type === "month"
				? leaderboard?.month
				: type === "day"
				? leaderboard?.day
				: leaderboard?.data
		);
	}, [leaderboard?.data, type, leaderboard?.day, leaderboard?.month]);

	useEffect(() => {
		dispatch(manageLeaderboard("get", { type }));
	}, [dispatch, type]);

	useEffect(() => {
		if (!auth?.isAuth) navigate(1);
	}, [auth?.isAuth, navigate]);

	if (!state && !leaderboard?.data) return <LoadingLoader />;

	return (
		<div className=" mx-auto overflow-x-hidden">
			<NavContact />
			<ExampageNavbar />
			<div
				className=" bg-cover bg-no-repeat bg-center h-full w-full"
				style={{ backgroundImage: `url(${Leaderboard})` }}>
				<div className="mx-auto py-10 px-8 md:px-12 lg:px-0">
					<div className=" lg:flex md:grid grid space-y-10 md:space-y-0 lg:space-y-0 justify-between">
						<div className=" lg:w-3/5 md:w-full w-full text-white grid justify-end lg:space-y-[-60px] md:space-y-10 space-y-10">
							<div className=" grid lg:space-y-[-100px] md:space-y-10 space-y-10">
								<div className=" flex justify-center text-center font-Segoe">
									<p className=" lg:text-[48px] md:text-[38px] text-[28px] font-Segoe font-bold">
										<span className=" lg:text-[48px] md:text-[38px] text-[28px]  font-boldfont-Segoe bg-clip-text bg-gradient-to-r from-leaderboardcol to-secleaderboardcol">
											LEADERBOARD
										</span>{" "}
										OVERVIEW
									</p>
								</div>

								<div className=" grid justify-center lg:w-[500px] text-center">
									<p className=" font-alata">
										Lorem ipsum dolor sit amet consectetur. At purus bibendum
										lectus integer gravida fames amet ridiculus. Blandit rhoncus
										iaculis consequat neque dictum aenean nisl. proin aliquam.
										Elementum.
									</p>
								</div>
							</div>

							<div>
								<button className=" w-[221px] h-[40px] bg-navbtncol font-Segoe">
									Recent Winners
								</button>
							</div>
						</div>
						<div className="  lg:w-2/5 md:w-full w-full">
							<img
								className=" w-[699px] h-[595.23px]"
								src={require("../../assets/Trophhy.png")}
								alt=""
							/>
						</div>
					</div>
				</div>

				{/* chat */}
				<div className=" relative lg:top-[-280px] md:top-[-380px] top-[-380px] text-white h-8 animate-pulse">
					{state?.[0] && (
						<div className=" w-[316px] h-[54px]  gap-10 lg:ml-[300px] bg-black opacity-30 rounded-full flex justify-center items-center ">
							<div>
								<img
									className=" w-[36px] h-[36px]"
									src={
										state?.[0]?.user?.image?.url ||
										require("../../assets/Chatimg.png")
									}
									alt=""
								/>
							</div>
							<div className="grid">
								<div className=" flex justify-between items-center">
									<div className=" flex gap-3 items-center">
										<p className=" text-[18px] font-bold font-Segoe">
											{state?.[0]?.user?.lastName}
										</p>
										<p className=" text-[12px] font-Segoe">
											{moment(state?.[0]?.createdAt).fromNow()}
										</p>
									</div>
									<div>
										<p className=" font-Segoe text-[14px]">
											{Number(state?.[0]?.totalScore || 0).toFixed(2)}{" "}
										</p>
									</div>
								</div>
								<div className=" flex justify-between items-center gap-4">
									<div>
										<p className=" text-[14px] font-Segoe">
											{state?.[0]?.user?.email}
										</p>
									</div>
									<div>
										<p className=" text-[14px] font-Segoe uppercase">
											{state?.[0]?.exam?.examType?.title}{" "}
										</p>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className=" lg:flex items-center lg:gap-[300px] space-y-5 md:space-y-0 lg:space-y-0  mt-5">
						{/* chat */}

						{state?.[1] && (
							<div className=" w-[316px] h-[54px] gap-10 bg-black opacity-30 rounded-full flex justify-center items-center ">
								<div>
									<img
										className=" w-[36px] h-[36px]"
										src={
											state?.[1]?.user?.image?.url ||
											require("../../assets/Chatimg.png")
										}
										alt=""
									/>
								</div>
								<div className="grid">
									<div className=" flex justify-between items-center">
										<div className=" flex gap-3 items-center">
											<p className=" text-[18px] font-bold font-Segoe">
												{state?.[1]?.user?.lastName}
											</p>
											<p className=" text-[12px] font-Segoe">
												{moment(state?.[1]?.createdAt).fromNow()}
											</p>
										</div>
										<div>
											<p className=" font-Segoe text-[14px]">
												{Number(state?.[1]?.totalScore || 0).toFixed(2)}{" "}
											</p>
										</div>
									</div>
									<div className=" flex justify-between items-center gap-4">
										<div>
											<p className=" text-[14px] font-Segoe">
												{state?.[1]?.user?.email}
											</p>
										</div>
										<div>
											<p className=" text-[14px] font-Segoe uppercase">
												{state?.[1]?.exam?.examType?.title}{" "}
											</p>
										</div>
									</div>
								</div>
							</div>
						)}
						{/* chat */}

						{state?.[2] && (
							<div className=" w-[316px] h-[54px] gap-10 bg-black opacity-30 rounded-full flex justify-center items-center ">
								<div>
									<img
										className=" w-[36px] h-[36px]"
										src={
											state?.[2]?.user?.image?.url ||
											require("../../assets/Chatimg.png")
										}
										alt=""
									/>
								</div>
								<div className="grid">
									<div className=" flex justify-between items-center">
										<div className=" flex gap-3 items-center">
											<p className=" text-[18px] font-bold font-Segoe">
												{state?.[2]?.user?.lastName}
											</p>
											<p className=" text-[12px] font-Segoe">
												{moment(state?.[2]?.createdAt).fromNow()}
											</p>
										</div>
										<div>
											<p className=" font-Segoe text-[14px]">
												{Number(state?.[2]?.totalScore || 0).toFixed(2)}{" "}
											</p>
										</div>
									</div>
									<div className=" flex justify-between items-center gap-4">
										<div>
											<p className=" text-[14px] font-Segoe">
												{state?.[2]?.user?.email}
											</p>
										</div>
										<div>
											<p className=" text-[14px] font-Segoe uppercase">
												{state?.[2]?.exam?.examType?.title}{" "}
											</p>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>

					<div className="  lg:flex justify-center lg:pt-10 space-y-5 md:space-y-0 lg:space-y-0 lg:mt-0 md:mt-5 mt-5 items-center gap-10">
						{/* chat */}

						{state?.[3] && (
							<div className=" w-[316px] h-[54px] lg:mb-32 gap-10 bg-black opacity-30 rounded-full flex justify-center items-center ">
								<div>
									<img
										className=" w-[36px] h-[36px]"
										src={
											state?.[3]?.user?.image?.url ||
											require("../../assets/Chatimg.png")
										}
										alt=""
									/>
								</div>
								<div className="grid">
									<div className=" flex justify-between items-center">
										<div className=" flex gap-3 items-center">
											<p className=" text-[18px] font-bold font-Segoe">
												{state?.[3]?.user?.lastName}
											</p>
											<p className=" text-[12px] font-Segoe">
												{moment(state?.[3]?.createdAt).fromNow()}
											</p>
										</div>
										<div>
											<p className=" font-Segoe text-[14px]">
												{Number(state?.[3]?.totalScore || 0).toFixed(2)}{" "}
											</p>
										</div>
									</div>
									<div className=" flex justify-between items-center gap-4">
										<div>
											<p className=" text-[14px] font-Segoe">
												{state?.[3]?.user?.email}
											</p>
										</div>
										<div>
											<p className=" text-[14px] font-Segoe uppercase">
												{state?.[3]?.exam?.examType?.title}{" "}
											</p>
										</div>
									</div>
								</div>
							</div>
						)}
						{/* chat */}

						{state?.[4] && (
							<div className=" w-[316px] h-[54px]  gap-10 bg-black opacity-30 rounded-full flex justify-center items-center ">
								<div>
									<img
										className=" w-[36px] h-[36px]"
										src={
											state?.[4]?.user?.image?.url ||
											require("../../assets/Chatimg.png")
										}
										alt=""
									/>
								</div>
								<div className="grid">
									<div className=" flex justify-between items-center">
										<div className=" flex gap-3 items-center">
											<p className=" text-[18px] font-bold font-Segoe">
												{state?.[4]?.user?.lastName}
											</p>
											<p className=" text-[12px] font-Segoe">
												{moment(state?.[4]?.createdAt).fromNow()}
											</p>
										</div>
										<div>
											<p className=" font-Segoe text-[14px]">
												{Number(state?.[4]?.totalScore || 0).toFixed(2)}{" "}
											</p>
										</div>
									</div>
									<div className=" flex justify-between items-center gap-4">
										<div>
											<p className=" text-[14px] font-Segoe">
												{state?.[4]?.user?.email}
											</p>
										</div>
										<div>
											<p className=" text-[14px] font-Segoe uppercase">
												{state?.[4]?.exam?.examType?.title}{" "}
											</p>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className=" grid px-8 md:px-12 lg:px-20 py-10">
				<div className=" flex gap-10 justify-start items-center">
					<p
						onClick={() => setType("day")}
						className={`text-[20px] font-Segoe text-exampagecol cursor-pointer ${
							type === "day" ? "font-bold" : ""
						}`}>
						Today
					</p>
					<p
						onClick={() => setType("month")}
						className={`text-[20px] font-Segoe text-exampagecol cursor-pointer ${
							type === "month" ? "font-bold" : ""
						}`}>
						This month
					</p>
					<p
						onClick={() => setType("all")}
						className={`text-[20px] font-Segoe text-black cursor-pointer ${
							type === "all" ? "font-bold" : ""
						}`}>
						All the time
					</p>
				</div>

				<div className=" grid justify-center items-center bg-cover bg-no-repeat bg-center ">
					<div
						className=" w-full h-[324px]"
						style={{
							backgroundImage: `url(${LeaderboardRect})`,
						}}>
						<div className=" grid items-center justify-center">
							<img
								className=" lg:w-[348px] h-[228px] animate-bounce z-10"
								src={require("../../assets/LGroup.png")}
								alt=""
							/>
						</div>
					</div>

					<div className="grid justify-center items-center  card bg-white shadow-md p-5">
						{state?.docs?.map((item, index) => (
							<div
								key={index}
								className="w-full  mb-4 md:border-0  border lg:border-0 border-b-landingpatnercol lg:p-0 md:p-0 p-2 md:gap-0 lg:gap-0 gap-10">
								<div className=" lg:flex md:flex items-center justify-between">
									<div className=" flex items-center justify-between  lg:gap-32 md:gap-12">
										<div className=" flex items-center lg:py-0 md:py-0 py-2 justify-start lg:gap-10 md: gap-5">
											<div className=" flex justify-center">
												<p>{index + 1}</p>
											</div>
											<div>
												<img
													className=" w-[40px] h-[40px]"
													src={
														item?.user?.image?.url ||
														data?.[index % state?.length]?.image
													}
													alt=""
												/>
											</div>
											<div>
												<p className=" text-[15px] font-Segoe">
													{item?.user?.firstName} {item?.user?.lastName}{" "}
													{item?.user?._id === auth?.user?._id ? "(You)" : ""}
												</p>
											</div>
										</div>
										<div className="  ">
											<div className="flex justify-end items-start">
												<p className="text-[15px] font-Segoe">
													{item?.user?.telephone}
												</p>
											</div>
										</div>
									</div>
									<div className=" flex items-center lg:gap-32 md:gap-12 gap-10 ">
										<div>
											<p className="text-[15px] font-Segoe">
												{item?.user?.email}
											</p>
										</div>
										<div>
											<p className="text-[15px] font-Segoe">
												{Number(item?.totalScore || 0).toFixed(2)}
											</p>
										</div>
										<div>
											{/* <p className="text-[15px] font-Segoe text-exampagecol uppercase">
												{item?.exam?.examType?.title}
												{" | "}
												{item?.exam?.subject?.title}
												{" | "}
												{item?.exam?.year?.title}
											</p> */}
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

			<div className="px-8 md:px-12 lg:px-20 py-10">
				<div>
					<p className=" text-[34px] font-bold font-alata">
						Milestones and Rewards
					</p>
				</div>
				<div className=" lg:flex md:grid-cols-3 grid lg:justify-between md:justify-center justify-center items-end animate-pulse">
					{/* 5 Star */}
					<div className=" w-[207px] h-[254px] grid justify-center">
						<div className=" px-5 py-2">
							<div className=" flex items-center">
								<img
									className=" w-[24.03px] h-[23.07px]"
									src={require("../../assets/Star.png")}
									alt=""
								/>
								<p>5</p>
							</div>

							<p className=" text-[14px] font-inter">Approved Reviews</p>
						</div>

						<div className=" w-[207px] h-[194px] rounded-md bg-gradient-to-b from-rewardone via-rewardtwo to-rewardthree grid justify-center p-3">
							<div className="">
								<p className=" text-[14px] w-[168px]">
									Lorem ipsum dolor sit amet
								</p>
							</div>
						</div>
					</div>

					{/* 20 Star */}
					<div className=" w-[207px] h-[326px] grid justify-center">
						<div className=" px-5 py-2">
							<div className=" flex items-center">
								<img
									className=" w-[24.03px] h-[23.07px]"
									src={require("../../assets/Star.png")}
									alt=""
								/>
								<p>20</p>
							</div>

							<p className=" text-[14px] font-inter">Approved Reviews</p>
						</div>

						<div className=" w-[207px] h-[266px] rounded-md bg-gradient-to-b from-rewardone via-rewardtwo to-rewardthree grid justify-center p-3">
							<div className=" ">
								<p className=" text-[14px] w-[168px]">
									Lorem ipsum dolor sit amet
								</p>
								<p className=" text-[14px] w-[168px]">
									Lorem ipsum dolor sit amet
								</p>
							</div>
						</div>
					</div>

					{/* 35 Star */}
					<div className=" w-[207px] h-[370px] grid justify-center">
						<div className=" px-5 py-2">
							<div className=" flex items-center">
								<img
									className=" w-[24.03px] h-[23.07px]"
									src={require("../../assets/Star.png")}
									alt=""
								/>
								<p>35</p>
							</div>

							<p className=" text-[14px] font-inter">Approved Reviews</p>
						</div>

						<div className=" w-[207px] h-[299px] rounded-md bg-gradient-to-b from-rewardone via-rewardtwo to-rewardthree grid justify-center p-3">
							<div className="">
								<p className=" text-[14px] w-[168px]">
									Lorem ipsum dolor sit amet
								</p>
								<p className=" text-[14px] w-[168px]">
									Lorem ipsum dolor sit amet
								</p>
								<p className=" text-[14px] w-[168px]">
									Lorem ipsum dolor sit amet
								</p>
							</div>
						</div>
					</div>
					{/* 50 Star */}
					<div className=" w-[207px] h-[403px] grid justify-center">
						<div className=" px-5 py-2">
							<div className=" flex items-center">
								<img
									className=" w-[24.03px] h-[23.07px]"
									src={require("../../assets/Star.png")}
									alt=""
								/>
								<p>50</p>
							</div>

							<p className=" text-[14px] font-inter">Approved Reviews</p>
						</div>

						<div className=" w-[207px] h-[343px] rounded-md bg-gradient-to-b from-rewardone via-rewardtwo to-rewardthree grid justify-center p-3">
							<div className="">
								<p className=" text-[14px] w-[168px]">
									Lorem ipsum dolor sit amet
								</p>
								<p className=" text-[14px] w-[168px]">
									Lorem ipsum dolor sit amet
								</p>
								<p className=" text-[14px] w-[168px]">
									Lorem ipsum dolor sit amet
								</p>
							</div>
						</div>
					</div>
					{/* 50 Star */}
					<div className=" w-[207px] h-[434px] grid justify-center">
						<div className=" px-5 py-2">
							<div className=" flex items-center">
								<img
									className=" w-[24.03px] h-[23.07px]"
									src={require("../../assets/Star.png")}
									alt=""
								/>
								<p> {">"}50</p>
							</div>

							<p className=" text-[14px] font-inter">Approved Reviews</p>
						</div>

						<div className=" w-[207px] h-[374px] rounded-md bg-gradient-to-b from-rewardone via-rewardtwo to-rewardthree grid justify-center p-3">
							<div className=" ">
								<p className=" text-[14px] w-[168px]">
									Lorem ipsum dolor sit amet
								</p>
								<p className=" text-[14px] w-[168px]">
									Lorem ipsum dolor sit amet
								</p>
								<p className=" text-[14px] w-[168px]">
									Lorem ipsum dolor sit amet
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Firstfooter />
		</div>
	);
};

export default LeaderBoard;
