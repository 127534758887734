import React from "react";

import NavContact from "../navbar/[navcontact]";
import ExampageNavbar from "../navbar/[exampagenavbar]";
import Secfooter from "../../components/footers/Secfooter";
import SecondQuestion from "../../components/sec-question-and-answer/SecondQuestion";
import { Link } from "react-router-dom";

const MathPage = () => {
  return (
    <div className=" mx-auto overflow-x-hidden">
      <NavContact />
      <ExampageNavbar />
      <div className=" grid px-8 md:px-12 lg:px-20 py-10 gap-10">
        <div>
          <p className=" text-[24px] font-Segoe">
            2022 MATHEMATICS PAST QUESTIONS
          </p>
        </div>

        <div>
          <SecondQuestion />
        </div>

        <div className=" flex justify-between items-center lg:px-28">
          <Link
            to="/waec-chemistry"
            className=" flex items-center cursor-pointer w-[194px] h-[50px] justify-start bg-exampageseccol rounded-full"
          >
            <img
              className=" w-[50px] h-[50px]"
              src={require("../../assets/Prev.png")}
              alt=""
            />
            <p>Previous</p>
          </Link>
          <Link
            to="/waec-submit"
            className="cursor-pointer w-[158px] h-[46px] bg-navbtncol flex justify-center items-center text-white rounded-full"
          >
            Submit
          </Link>
        </div>
      </div>

      {/* footer */}
      <Secfooter />
    </div>
  );
};

export default MathPage;
