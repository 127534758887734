/* eslint-disable no-undef */
import { createElement, useEffect } from "react";
import { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import { ErrorPage } from "./components";
import { GlobalState } from "./data/Context";
import { LoadingLoader } from "./components/button/button";

const GeneratePage = (pageName, folder) => {
	const component = () => require(`./${folder}/${pageName}`).default;
	let navigate = useNavigate();
	try {
		return createElement(component());
	} catch (e) {
		console.log(e);
		navigate("/");
	}
};

const PageRender = () => {
	const { auth } = useContext(GlobalState);
	const { page, id, step } = useParams();
	const escape2 = [
			"home",
			"JambLandingpage",
			"testing",
			"submission",
			"review",
		],
		navigate = useNavigate();

	useEffect(() => {
		// if (!auth?.isAuth) {
		// 	if (!error?.errorText) {
		// 		if (page !== "login" && page !== "register") {
		// 			navigate("/");
		// 		}
		// 		clearErrors();
		// 	}
		// }
		if (auth?.isAuth) {
			if (["register", "login", "create-account"]?.includes(page)) {
				navigate("/");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, auth?.isAuth, navigate]);

	if (auth.token && auth.loading) return <LoadingLoader />;

	let pageName = "";
	if (step) {
		pageName = `${page}/${id}/${"[id]"}`;
	} else if (id) {
		if (
			(page === "home" && escape2.includes(id)) ||
			(page === "exam-selection" && escape2.includes(id)) ||
			(page === "jamb-page" && escape2.includes(id)) ||
			(page === "testtwo" && escape2.includes(id))
		) {
			pageName = `${page}/${id}`;
		} else {
			pageName = `${page}/${"[id]"}`;
		}
	} else {
		pageName = `${page}`;
	}
	return GeneratePage(pageName, "screens");
};

export default PageRender;
