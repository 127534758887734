import React, { useState, useEffect } from "react";
import NavContact from "../navbar/[navcontact]";
import ExampageNavbar from "../navbar/[exampagenavbar]";
import Secfooter from "../../components/footers/Secfooter";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ansArr } from "../../components/question-and-answer/QuestionAndAnswer";

const ReviewAnswers = () => {
	let { state } = useLocation(),
		{ auth } = useSelector(s => s),
		navigate = useNavigate(),
		data = state?.questions;

	useEffect(() => {
		if (!state || !auth?.isAuth) return navigate(-1);
	}, [state, auth?.isAuth, navigate]);
	// console.log({ data });
	return (
		<div className="overflow-x-hidden mx-auto">
			<NavContact />
			<ExampageNavbar />
			<div className="container bg-[#fafafa] p-12">
				<div className="space-y-6">
					{data?.map((item, i) => (
						<div key={i} className="">
							<h2 className="text-xl segoe text-[#162023] font-normal">
								Question {i + 1}
							</h2>
							<h2 className="text-lg segoe font-semibold text-[#162023]">
								{item?.question} ?
							</h2>
							{item?.file && (
								<div className="flex flex-col items-center gap-4 py-8">
									<img
										src={item?.file?.url || null}
										alt=""
										className="h-80 w-auto rounded-sm border"
									/>
								</div>
							)}
							<div className="space-y-3">
								{item?.options?.map((option, i) => (
									<AnswerChecker
										option={option}
										info={state?.answers}
										question={item}
										index={i + 1}
									/>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="px-8 md:px-12 lg:px-20 py-5 grid h-72 w-full justify-center items-center"></div>
			<div className="px-8 md:px-12 lg:px-20 py-5 flex justify-center items-center ">
				<Link
					to="/exam-page"
					className=" w-[309px] h-[65px] rounded-full flex justify-center items-center bg-exampagetestcol text-white">
					Back to Practice
				</Link>
			</div>

			{/* Footer */}
			<Secfooter />
		</div>
	);
};

export default ReviewAnswers;

const AnswerChecker = ({ option, info, question, index }) => {
	let [isCorrect, setIsCorrect] = useState(null),
		[selectOption, setSelectOption] = useState(null);

	useEffect(() => {
		if (info && question && index) {
			let finder = info?.find(it => it?.question === question?._id);
			if (finder) {
				let finder2 = question?.options?.find(it => it?._id === option?._id);
				if (finder2?._id === finder?.option && finder2?.isCorrect)
					setIsCorrect(true);
				setSelectOption(finder?.option);
			}
		}
	}, [info, question, index, option]);

	// console.log({ isCorrect, selectOption, index });

	return (
		<div
			className={`w-full py-4 rounded-2xl px-6 overflow-x-hidden ${
				!option?.file ? "flex items-center" : ""
			}`}
			style={{
				background:
					isCorrect && selectOption === option?._id
						? "#14AE5C"
						: option?.isCorrect
						? "#14AE5C"
						: selectOption === option?._id
						? "#dc3545"
						: "#f1f1f1",
			}}>
			<h6
				className="text-medium poppins text-sm"
				style={{
					color:
						isCorrect && selectOption === option?._id
							? "#fff"
							: option?.isCorrect
							? "#fff"
							: selectOption === option?._id
							? "#fff"
							: "#162023",
				}}>
				{ansArr?.[index - 1]}. {option?.option}
			</h6>
			{option?.file && (
				<div className="flex flex-col items-center gap-4">
					<img
						src={option?.file?.url || null}
						alt=""
						className="h-52 w-52 rounded-sm border"
					/>
				</div>
			)}
		</div>
	);
};
