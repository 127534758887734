import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavContact from "../navbar/[navcontact]";
import ExampageNavbar from "../navbar/[exampagenavbar]";
import Secfooter from "../../components/footers/Secfooter";
import { Link } from "react-router-dom";
import moment from "moment";

const Submission = () => {
	let { state } = useLocation(),
		navigate = useNavigate();

	if (!state) return navigate(-1);

	const percentage = (state?.score / 100) * 100;

	// Time spent
let initD = state?.exam?.duration,
	hours = Math.floor(initD / 60),
	minutes = initD % 60,
	initSpent = state?.timeSpent,
	hoursSpent = Math.floor(initSpent / 60),
	minutesSpent = initSpent % 60;
const timeSpent = `${hoursSpent}:${minutesSpent}:00`;
const totalTime = `${hours}:${minutes}:00`;
const timeSpentInSeconds = timeToSeconds(timeSpent);
const totalTimeInSeconds = timeToSeconds(totalTime);
const percentages = (timeSpentInSeconds / totalTimeInSeconds) * 100;

function timeToSeconds(time) {
	const parts = time.split(":");
	return (
		parseInt(parts[0]) * 3600 + parseInt(parts[1]) * 60 + parseInt(parts[2])
	);
}

// correct answer
const correctanspercentage =
	(state?.correctAnswer / state?.totalQuestions) * 100;

// visited answer
const answerspercentage = (state?.visitedAnswer / state?.totalQuestions) * 100;

// correct answer
// console.log({ state });
return (
	<div className=" mx-auto overflow-x-hidden">
		<NavContact />
		<ExampageNavbar />
		<div className="px-8 md:px-12 lg:px-20 py-5">
			<div className=" lg:flex md:flex grid items-center gap-3">
				<div className=" flex items-center gap-2">
					<img
						className=" w-[45px] h-[45.79px]"
						src={require("../../assets/PartyEmoji.png")}
						alt=""
					/>
					<p className=" font-Segoe text-[28px] text-exampagecol font-bold">
						Your Results:
					</p>
				</div>
				<div className=" flex items-center gap-2">
					<p className=" font-Segoe text-[24px]">
						This is an excellent. Good job done
					</p>
					<img
						className=" w-[32px] h-[34.4px]"
						src={require("../../assets/ThumbsUpEmoji.png")}
						alt=""
					/>
				</div>
			</div>
		</div>

		<div className="px-8 md:px-12 lg:px-20 py-5">
			<ul className="flex items-center gap-5">
				<li className=" text-[16.03px] font-Segoe text-exampagecol font-semibold px-2 py-2 card shadow-md uppercase">
					Subject : {state?.exam?.subject?.title}
				</li>

				<li className="  px-2 py-2 card shadow-md  lg:flex md:flex grid items-center gap-2 uppercase">
					<p className="text-[16.03px] font-Segoe text-exampagecol font-semibold">
						Exam Name :{" "}
					</p>
					{/* <img
							className=" w-[62.33px] h-[21.37px]"
							src={require("../../assets/UTME.png")}
							alt=""
						/> */}
					{state?.exam?.examType?.title}
				</li>
				<li className=" text-[16.03px] font-Segoe text-exampagecol font-semibold px-2 py-2 card shadow-md uppercase">
					Year: {state?.exam?.year?.title}
				</li>
			</ul>
		</div>

		<div className="px-8 md:px-12 lg:px-20 py-5 grid">
			<div className="grid gap-10 shadow-lg px-10 py-5">
				<div className=" lg:flex md:grid grid items-center lg:gap-20 ">
					<div className=" w-[186.09px] h-[77.46px] shadow-md grid items-center justify-center">
						<p className=" font-Segoe text-exampagecol text-center text-[14.25px] font-semibold">
							Your Score
						</p>
						<p className=" text-jambpagetesultcol text-center font-Segoe text-[17.81px]">
							{state?.score}/100
						</p>
					</div>

					<div className="grid w-full gap-3">
						<div className="flex justify-between items-center">
							<div className=" text-[16.03px] font-Segoe text">Your Score</div>
							<div className=" font-Segoe text-exampagecol text-[12.47px]">
								100
							</div>
						</div>
						<div className=" w-full bg-white">
							<div
								className="lg:w-[602.77px] h-[26.71px] grid justify-center items-center bg-scorecol"
								style={{ width: `${percentage}%` }}>
								<p className="text-[12.47px] font-Segoe">
									{percentage.toFixed(2)}%
								</p>
							</div>
						</div>
					</div>
				</div>

				{/* Time spent */}

				{initD && (
					<div className=" lg:flex md:grid grid items-center lg:gap-20">
						<div className=" w-[186.09px] h-[77.46px] shadow-md grid items-center justify-center">
							<p className=" font-Segoe text-exampagecol text-center text-[14.25px] font-semibold">
								Time Spent
							</p>
							<p className=" text-jambpagetesultcol text-center font-Segoe text-[17.81px]">
								{state?.timeSpent} mins
							</p>
						</div>

						<div className="grid w-full gap-3">
							<div className="flex justify-between items-center">
								<div className=" text-[16.03px] font-Segoe text">
									Time Spent
								</div>
								<div className=" font-Segoe text-exampagecol text-[12.47px]">
									{state?.exam?.duration}
								</div>
							</div>
							<div className=" w-full bg-white">
								<div
									style={{ width: `${percentages}%` }}
									className=" lg:w-[673.11px]  h-[26.71px] grid justify-center items-center bg-timrspentcol">
									<p className="text-[12.47px] font-Segoe">
										{percentages.toFixed(2)}%
									</p>
								</div>
							</div>
						</div>
					</div>
				)}

				{/* Correct Answer */}

				<div className=" lg:flex md:grid grid items-center lg:gap-20">
					<div className=" w-[186.09px] h-[77.46px] shadow-md grid items-center justify-center">
						<p className=" font-Segoe text-exampagecol text-center text-[14.25px] font-semibold">
							Correct Answers
						</p>
						<p className=" text-jambpagetesultcol text-center font-Segoe text-[17.81px]">
							{state?.correctAnswer}
						</p>
					</div>

					<div className="grid w-full gap-3">
						<div className="flex justify-between items-center">
							<div className=" text-[16.03px] font-Segoe text">
								Answered Questions
							</div>
							<div className=" font-Segoe text-exampagecol text-[12.47px]">
								{state?.totalQuestions}
							</div>
						</div>
						<div className=" w-full bg-white">
							<div
								style={{ width: `${correctanspercentage}%` }}
								className=" lg:w-[673.11px]  h-[26.71px] grid justify-center items-center bg-correctanscol">
								<p className="text-[12.47px] font-Segoe">
									{" "}
									{correctanspercentage.toFixed(2)}%
								</p>
							</div>
						</div>
					</div>
				</div>

				{/* Exam and Date */}
				<div className=" lg:flex md:grid grid items-center lg:gap-20">
					<div className=" w-[186.09px] h-[77.46px] shadow-md grid items-center justify-center">
						<p className=" font-Segoe text-exampagecol text-center text-[14.25px] font-semibold">
							Exam Date & Time
						</p>
						<p className=" text-jambpagetesultcol text-center font-Segoe text-[17.81px]">
							{moment(state?.createdAt).format("DD.MM.YYYY | HH:mm")}
						</p>
					</div>

					<div className="grid w-full gap-3">
						<div className="flex justify-between items-center">
							<div className=" text-[16.03px] font-Segoe text">
								Visited Answers
							</div>
							<div className=" font-Segoe text-exampagecol text-[12.47px]">
								{state?.totalQuestions}
							</div>
						</div>
						<div className=" w-full bg-white">
							<div
								style={{ width: `${answerspercentage}%` }}
								className=" lg:w-[673.11px]  h-[26.71px] grid justify-center items-center bg-visitedanscol">
								<p className="text-[12.47px] font-Segoe">
									{answerspercentage.toFixed(2)}%
								</p>
							</div>
						</div>
					</div>
				</div>

				{/* correct answer */}

				<div className=" lg:flex md:grid grid items-center lg:gap-20">
					<div className=" w-[186.09px] h-[77.46px] grid items-center justify-center"></div>

					<div className="grid w-full gap-3">
						<div className="flex justify-between items-center">
							<div className=" text-[16.03px] font-Segoe text">
								Correct Answers
							</div>
							<div className=" font-Segoe text-exampagecol text-[12.47px]">
								{state?.totalQuestions}
							</div>
						</div>
						<div className=" w-full bg-white">
							<div
								style={{ width: `${percentage}%` }}
								className=" lg:w-[673.11px]  h-[26.71px] grid justify-center items-center bg-correctanscol">
								<p className="text-[12.47px] font-Segoe">
									{" "}
									{percentage.toFixed(2)}%
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="px-8 md:px-12 lg:px-20 py-5 flex justify-center items-center ">
			<Link
				to="/exam-page"
				className=" w-[309px] h-[65px] rounded-full flex justify-center items-center bg-exampagetestcol text-white">
				Practice Again
			</Link>
			<Link
				to="/exam-selection/review"
				state={state}
				className=" w-[309px] h-[65px] flex justify-center items-center rounded-full bg-navbtncol text-white">
				Review answers
			</Link>
		</div>

		{/* Footer */}
		<Secfooter />
	</div>
);
};

export default Submission;
