import React from "react";
// import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const Card = ({ subjects, selected, onClick }) => {
	return (
		<div
			className={`transition-transform border bg-gray-50 grid justify-center items-center hover:scale-110 duration-300 ease-in ${
				selected ? "border-purple-500" : ""
			}`}
			onClick={onClick}>
			<div className="px-2 py-2 cursor-pointer  font-alata text-[14px] grid lg:justify-center md:justify-start justify-start items-center uppercase">
				{subjects}
			</div>
		</div>
	);
};

// Years card

const Yearcard = ({ years, selected, onClick }) => {
	return (
		<div
			className="transition-transform hover:scale-110 duration-300 ease-in"
			onClick={onClick}>
			<div
				className={`px-2 py-2 cursor-pointer border bg-gray-50 font-alata text-[14px] grid lg:justify-center md:justify-start justify-start items-center uppercase ${
					selected ? "border-purple-500" : ""
				}`}>
				{years}
			</div>
		</div>
	);
};

const SubjectYears = ({data, setData}) => {
	// const Subjectcard = [
	//   {
	//     subjects: "Mathematics",
	//   },
	//   {
	//     subjects: "Physics",
	//   },
	//   {
	//     subjects: "Geography",
	//   },
	//   {
	//     subjects: "Biology",
	//   },
	//   {
	//     subjects: "Literature - in -English",
	//   },
	//   {
	//     subjects: "Chemistry",
	//   },
	//   {
	//     subjects: "Economics",
	//   },

	//   {
	//     subjects: "Mathematics",
	//   },
	//   {
	//     subjects: "English Language",
	//   },
	//   {
	//     subjects: "Biology",
	//   },
	//   {
	//     subjects: "French",
	//   },
	//   {
	//     subjects: "Literature - in -English",
	//   },
	//   {
	//     subjects: "Chemistry",
	//   },
	//   {
	//     subjects: "Economics",
	//   },

	//   {
	//     subjects: "Mathematics",
	//   },
	//   {
	//     subjects: "English Language",
	//   },
	//   {
	//     subjects: "Biology",
	//   },
	//   {
	//     subjects: "French",
	//   },
	//   {
	//     subjects: "Literature - in -English",
	//   },
	//   {
	//     subjects: "Chemistry",
	//   },
	//   {
	//     subjects: "Economics",
	//   },

	//   {
	//     subjects: "Mathematics",
	//   },
	//   {
	//     subjects: "English Language",
	//   },
	//   {
	//     subjects: "Biology",
	//   },
	//   {
	//     subjects: "French",
	//   },
	//   {
	//     subjects: "Literature - in -English",
	//   },
	//   {
	//     subjects: "Chemistry",
	//   },
	//   {
	//     subjects: "Economics",
	//   },

	//   {
	//     subjects: "Mathematics",
	//   },
	//   {
	//     subjects: "English Language",
	//   },
	//   {
	//     subjects: "Biology",
	//   },
	//   {
	//     subjects: "French",
	//   },
	//   {
	//     subjects: "Literature - in -English",
	//   },
	//   {
	//     subjects: "Chemistry",
	//   },
	//   {
	//     subjects: "Economics",
	//   },
	// ];

	// const Pickyear = [
	// 	{
	// 		years: "2023",
	// 	},
	// 	{
	// 		years: "2022",
	// 	},
	// 	{
	// 		years: "2021",
	// 	},
	// 	{
	// 		years: "2020",
	// 	},
	// 	{
	// 		years: "2029",
	// 	},
	// 	{
	// 		years: "2028",
	// 	},
	// 	{
	// 		years: "2027",
	// 	},
	// 	{
	// 		years: "2026",
	// 	},
	// 	{
	// 		years: "2025",
	// 	},
	// 	{
	// 		years: "2024",
	// 	},
	// 	{
	// 		years: "2013",
	// 	},

	// 	{
	// 		years: "2023",
	// 	},
	// 	{
	// 		years: "2022",
	// 	},
	// 	{
	// 		years: "2021",
	// 	},
	// 	{
	// 		years: "2020",
	// 	},
	// 	{
	// 		years: "2029",
	// 	},
	// 	{
	// 		years: "2028",
	// 	},
	// 	{
	// 		years: "2027",
	// 	},
	// 	{
	// 		years: "2026",
	// 	},
	// 	{
	// 		years: "2025",
	// 	},
	// 	{
	// 		years: "2024",
	// 	},
	// 	{
	// 		years: "2013",
	// 	},

	// 	{
	// 		years: "2023",
	// 	},
	// 	{
	// 		years: "2022",
	// 	},
	// 	{
	// 		years: "2021",
	// 	},
	// 	{
	// 		years: "2020",
	// 	},
	// 	{
	// 		years: "2029",
	// 	},
	// 	{
	// 		years: "2028",
	// 	},
	// 	{
	// 		years: "2027",
	// 	},
	// 	{
	// 		years: "2026",
	// 	},
	// 	{
	// 		years: "2025",
	// 	},
	// 	{
	// 		years: "2024",
	// 	},
	// 	{
	// 		years: "2013",
	// 	},

	// 	{
	// 		years: "2023",
	// 	},
	// 	{
	// 		years: "2022",
	// 	},
	// 	{
	// 		years: "2021",
	// 	},
	// 	{
	// 		years: "2020",
	// 	},
	// 	{
	// 		years: "2029",
	// 	},
	// 	{
	// 		years: "2028",
	// 	},
	// 	{
	// 		years: "2027",
	// 	},
	// 	{
	// 		years: "2026",
	// 	},
	// 	{
	// 		years: "2025",
	// 	},
	// 	{
	// 		years: "2024",
	// 	},
	// 	{
	// 		years: "2013",
	// 	},

	// 	{
	// 		years: "2023",
	// 	},
	// 	{
	// 		years: "2022",
	// 	},
	// 	{
	// 		years: "2021",
	// 	},
	// 	{
	// 		years: "2020",
	// 	},
	// 	{
	// 		years: "2029",
	// 	},
	// 	{
	// 		years: "2028",
	// 	},
	// 	{
	// 		years: "2027",
	// 	},
	// 	{
	// 		years: "2026",
	// 	},
	// 	{
	// 		years: "2025",
	// 	},
	// 	{
	// 		years: "2024",
	// 	},
	// 	{
	// 		years: "2013",
	// 	},

	// 	{
	// 		years: "2023",
	// 	},
	// 	{
	// 		years: "2022",
	// 	},
	// 	{
	// 		years: "2021",
	// 	},
	// 	{
	// 		years: "2020",
	// 	},
	// 	{
	// 		years: "2005",
	// 	},
	// 	{
	// 		years: "2004",
	// 	},
	// 	{
	// 		years: "2003",
	// 	},
	// 	{
	// 		years: "2002",
	// 	},
	// 	{
	// 		years: "2001",
	// 	},
	// 	{
	// 		years: "2000",
	// 	},
	// 	{
	// 		years: "1999",
	// 	},
	// ];

	let { subject, year } = useSelector(s => s),
		{ state } = useLocation();

	return (
		<>
			<div className="grid justify-center gap-10 items-center py-10 px-8 md:px-12 lg:px-20 ">
				<div className=" grid justify-center">
					<p className=" text-[24px] font-Segoe text-landingpatnercol font-bold">
						AVAILABLE SUBJECTS
					</p>
				</div>

				{/* subject card */}
				<div className="grid lg:grid-cols-7 lg:gap-5 md:gap-5 gap-5 md:grid-cols-4 grid-cols-2">
					{subject?.data?.docs
						?.filter(it => it?.examType?.includes(state?._id))
						?.map((card, index) => (
							<Card
								key={index}
								subjects={card.title}
								selected={data?.subject === card._id}
								onClick={() => setData({ ...data, subject: card._id })}
							/>
						))}
				</div>
			</div>

			{/* section3 Year */}

			{data?.subject && (
				<div className="grid justify-center items-center space-y-20 py-10 px-8 md:px-12 lg:px-20">
					<div className=" grid justify-center gap-8 items-center">
						<div className="flex justify-center">
							<p className=" text-[24px] text-landingpatnercol font-Segoe font-bold">
								{" "}
								PICK A YEAR
							</p>
						</div>

						<div className="grid lg:grid-cols-11 lg:gap-10 md:gap-10 gap-5 md:grid-cols-7 grid-cols-4">
							{year?.data?.docs
								?.filter(
									it =>
										it?.examType?.includes(state?._id) &&
										it?.subject?.includes(data?.subject)
								)
								?.map((yearcard, index) => (
									<Yearcard
										key={index}
										years={yearcard.title}
										selected={data?.year === yearcard._id}
										onClick={() => setData({ ...data, year: yearcard._id })}
									/>
								))}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default SubjectYears;
