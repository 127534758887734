import React from "react";
import { useState } from "react";
import { FaHamburger } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logoutMain } from "../../data/Reducers/UserReducer";

const SecNav = () => {
	const [menuOpen, setMenuOpen] = useState(false);

	const closeMenu = () => {
		setMenuOpen(false);
	};

	let { auth } = useSelector(s => s),
		dispatch = useDispatch();

	return (
		<nav className=" flex sticky top-0 font-segoe px-8 md:px-12 lg:px-20 py-2 items-center mx-auto">
			<div className=" grid space-y-3 items-center ">
				<div className=" flex font-segoe justify-between items-center text-white">
					<Link to="/home">
						<img
							className=" lg:w-[250px] md:w-[200px] w-[160px] lg:h-[47.2px] md:h-[43.2px] h-[42.2px]"
							src={require("../../assets/Logo.png")}
							alt=""
						/>
					</Link>

					<ul className=" lg:flex md:flex hidden items-center space-x-5">
						<Link
							to="/home"
							className="lg:text-[24px] md:text-[20px] cursor-pointer transition-transform hover:scale-110 duration-300 ease-in">
							Home
						</Link>
						<Link
							to="/exam-page"
							className="lg:text-[24px] md:text-[20px] cursor-pointer transition-transform hover:scale-110 duration-300 ease-in">
							Practice
						</Link>
						<Link
							to="/leader-board"
							className="lg:text-[24px] md:text-[20px] cursor-pointer transition-transform hover:scale-110 duration-300 ease-in">
							Leaderboard
						</Link>
					</ul>

					<div className=" lg:flex md:flex hidden gap-2">
						<img
							className=" w-[42px] h-[42px]"
							src={require("../../assets/Secnavlogo.png")}
							alt=""
						/>
						{auth?.isAuth && (
							<button
								onClick={() => dispatch(logoutMain())}
								className=" w-[188px] h-[50px] bg-navbtncol font-Segoe rounded-md cursor-pointer transition-transform hover:scale-110 duration-300 ease-in text-white">
								LOGOUT
							</button>
						)}
					</div>
				</div>
				<div>
					<img
						className=" w-full"
						src={require("../../assets/Navline.png")}
						alt=""
					/>
				</div>
			</div>

			{/* Mobile screen */}

			<div className="md:hidden">
				<button
					onClick={() => setMenuOpen(!menuOpen)}
					className="text-white focus:outline-none ">
					<FaHamburger />
				</button>
			</div>
			{menuOpen && (
				<div className="md:hidden absolute md:px-16 px-8 top-16 left-0 w-full bg-white p-4 space-y-8">
					<ul className=" grid items-center text-black space-y-5">
						<Link
							to="/home"
							onClick={closeMenu}
							className="text-[24px] cursor-pointer">
							Home
						</Link>
						<Link
							to="/exam-page"
							onClick={closeMenu}
							className="text-[24px] cursor-pointer">
							Practice
						</Link>
						<Link
							to="/leader-board"
							onClick={closeMenu}
							className="text-[24px] cursor-pointer">
							Leaderboard
						</Link>
					</ul>

					<div>
						<div onClick={closeMenu}>
							<img
								className=" w-[42px] h-[42px]"
								src={require("../../assets/Secnavlogo.png")}
								alt=""
							/>
						</div>
					</div>

					{/* social medial for small screen */}
					<div className="  lg:hidden md:hidden flex w-full justify-center items-center space-x-4">
						<img
							className=" w-[24px] h-[24px]"
							src={require("../../assets/Linkedin.png")}
							alt=""
						/>
						<img
							className=" w-[24px] h-[24px]"
							src={require("../../assets/Instagram.png")}
							alt=""
						/>
						<img
							className=" w-[24px] h-[24px]"
							src={require("../../assets/Twitter.png")}
							alt=""
						/>
						<img
							className=" w-[24px] h-[24px]"
							src={require("../../assets/Facebook.png")}
							alt=""
						/>
					</div>
				</div>
			)}
		</nav>
	);
};

export default SecNav;
